import React from 'react';
import { Text, ControlLink, Stack, Clickable } from '@moda/om';
import FileSaver from 'file-saver';
import { Address, ShipToAddress } from '../../types/address';
import { SelectedItemsInPage, ShipmentDetailsData } from '../../types/data';
import './ShipmentReview.scss';

interface Props {
  editItems: () => void;
  editAddress: () => void;
  editShipment: () => void;
  poShipTo: ShipToAddress | undefined;
  poItems: SelectedItemsInPage[] | undefined;
  shippingDetails: ShipmentDetailsData | undefined;
  poShipFrom: Address | undefined;
}
export const ShipmentReview: React.FC<Props> = ({
  poShipTo,
  editItems,
  poShipFrom,
  editAddress,
  editShipment,
  poItems,
  shippingDetails
}) => {
  const downloadPdf = (file: File) => {
    const blob = new Blob([file]);
    const fileName = file.name;
    FileSaver.saveAs(blob, fileName);
  };

  let totalSkuQty = 0;
  poItems?.forEach(item => {
    item?.skus?.forEach(sku => {
      if (sku.quantity) {
        totalSkuQty += Number(sku.quantity);
      }
    });
    return totalSkuQty;
  });

  return (
    <Stack space={6} className="ShipmentReview">
      <div className="ShipmentReview__details">
        <Stack
          space={1}
          direction="horizontal"
          justifyContent="space-between"
          className="ShipmentReview__heading"
        >
          <Text treatment="h6" className="ShipmentReview__heading-text">
            Ship From
          </Text>
          <ControlLink underlined={false} onClick={editAddress}>
            <Text treatment="h6" color="cornflower-blue">
              Edit
            </Text>
          </ControlLink>
        </Stack>
        <div className="ShipmentReview__review-item">
          {poShipFrom?.firstName} {poShipFrom?.lastName}
        </div>
        <div className="ShipmentReview__review-item">
          {poShipFrom?.addressLine1} {poShipFrom?.addressLine2}
        </div>
        <div className="ShipmentReview__review-item">
          {poShipFrom?.city}, {poShipFrom?.state}, {poShipFrom?.countryShortCode},{' '}
          {poShipFrom?.postalCode}
        </div>
        <div className="ShipmentReview__review-item">{poShipFrom?.phone}</div>
        <div className="ShipmentReview__heading">
          <Text treatment="h6" className="ShipmentReview__heading-text">
            Ship To
          </Text>
        </div>
        <div className="ShipmentReview__review-item">
          {poShipTo?.firstName} {poShipTo?.lastName}
        </div>
        <div className="ShipmentReview__review-item">
          {poShipTo?.addressLine1} {poShipTo?.addressLine2}
        </div>
        <div className="ShipmentReview__review-item">
          {poShipTo?.city}, {poShipTo?.state}, {poShipTo?.countryCode}, {poShipTo?.postalCode}
        </div>
      </div>
      <div className="ShipmentReview__details">
        <Stack
          space={1}
          direction="horizontal"
          justifyContent="space-between"
          className="ShipmentReview__heading"
        >
          <Text treatment="h6" className="ShipmentReview__heading-text">
            Shipping Date
          </Text>
          <ControlLink underlined={false} onClick={editShipment}>
            <Text treatment="h6" color="cornflower-blue">
              Edit
            </Text>
          </ControlLink>
        </Stack>
        <div className="ShipmentReview__review-item">{shippingDetails?.shipmentDate}</div>
        <div className="ShipmentReview__heading">
          <Text treatment="h6" className="ShipmentReview__heading-text">
            Packing Details
          </Text>
        </div>
        {shippingDetails?.cartonList.cartons.map((carton, index) => (
          <div key={index} className="ShipmentReview__review-item">
            <Text treatment="bold1">Carton {index + 1} </Text>
            {carton.weight}
            {shippingDetails?.cartonList.unit.mass}, {carton.length}
            {shippingDetails?.cartonList.unit.dimension}, {carton.width}
            {shippingDetails?.cartonList.unit.dimension}, {carton.height}
            {shippingDetails?.cartonList.unit.dimension}
          </div>
        ))}
        {shippingDetails?.shippingInformation && (
          <div className="ShipmentReview__review-item">
            <Text treatment="h6" className="ShipmentReview__heading-text">
              Shipping Information
            </Text>
            <div>{shippingDetails.shippingInformation?.carrier}</div>
            <div>{shippingDetails.shippingInformation?.trackingNumber}</div>
          </div>
        )}
        {shippingDetails && shippingDetails.uploadData && shippingDetails.uploadData.length > 0 && (
          <div className="ShipmentReview__heading">
            <Text treatment="h6" className="ShipmentReview__heading-text">
              Customs Documentation
            </Text>
            {shippingDetails?.uploadData?.map((file, index) => (
              <div key={index}>
                <Clickable onClick={() => downloadPdf(file)}>{file.name}</Clickable>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="ShipmentReview__details">
        <Stack
          space={1}
          direction="horizontal"
          justifyContent="space-between"
          className="ShipmentReview__heading"
        >
          <Text treatment="h6" className="ShipmentReview__heading-text">
            Item Summary
          </Text>
          <ControlLink underlined={false} onClick={editItems}>
            <Text treatment="h6" color="cornflower-blue">
              Edit
            </Text>
          </ControlLink>
        </Stack>
        <div className="ShipmentReview__review-item">Total Quantity: {totalSkuQty}</div>
        <table className="ShipmentReview__table">
          <thead className="ShipmentReview__thead">
            <tr className="ShipmentReview__row">
              <th colSpan={4} />
              <th className="ShipmentReview__row-header">Style Number</th>
              <th className="ShipmentReview__row-header">Style Name</th>
              <th className="ShipmentReview__row-header">Color</th>
              <th className="ShipmentReview__row-header">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {poItems?.map((item, index) => (
              <tr key={index}>
                <td colSpan={4}>
                  <img className="ShipmentReview__item-logo" alt="item" src={item.imageUrl} />
                </td>
                <td className="ShipmentReview__row-data">{item.styleNumber}</td>
                <td className="ShipmentReview__row-data">{item.styleName}</td>
                <td className="ShipmentReview__row-data">{item.vendorColor}</td>
                {item?.skus?.map((sku, index) => (
                  <td key={index} className="ShipmentReview__row-data">
                    {sku.skuSize}: {sku.quantity}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Stack>
  );
};
