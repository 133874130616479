import React from 'react';
import { Loading, Overlay, Stack } from '@moda/om';
import { useAuth0 } from '@auth0/auth0-react';
import { TopNavigation } from '../TopNavigation';

import './PageLayout.scss';

export const PageLayout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isLoading, error } = useAuth0();

  if (error) return <div>{error.message}</div>;

  return (
    <>
      <Stack className="PageLayout" space={0}>
        <TopNavigation />

        <Stack className="PageLayout__content" space={8}>
          {children}
        </Stack>
      </Stack>

      <Overlay show={isLoading}>
        <Loading />
      </Overlay>
    </>
  );
};
