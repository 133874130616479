import React, { useCallback } from 'react';
import { Stack, Button } from '@moda/om';
import { OrderNavBreadcrumbs } from '../OrderNavBreadcrumbs';
import { Mode as ShipmentMode } from '../../pages/ShipmentPage/ShipmentPage';
import { Address } from '../../types/address';
import { SelectedItemsInPage, SingleCarton } from '../../types/data';

import './ShipmentFormNavigation.scss';
import { hrefFor } from '../../routers';

interface Props {
  poNumber: string;
  mode: ShipmentMode;
  address: Address | undefined;
  cartonList: SingleCarton[];
  shippingDate: string;
  uploadData: File[] | unknown[];
  isDomesticShipment: boolean;
  fileSizeError: string;
  onClickButton: (buttonType: ButtonType) => void;
  items: SelectedItemsInPage[];
}

export type ButtonType = 'NEXT' | 'BACK' | 'SUBMIT' | 'RETURN TO PO';

export const ShipmentFormNavigation: React.FC<Props> = ({
  items,
  address,
  mode,
  cartonList,
  shippingDate,
  uploadData,
  isDomesticShipment,
  fileSizeError,
  onClickButton
}) => {
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      onClickButton((event.target as HTMLButtonElement).innerText as ButtonType);
    },
    [onClickButton]
  );

  const handleDisableState = () => {
    const invalidShippingDetails = cartonList.some(
      item => !item.height || !item.width || !item.length || !item.weight || item.error
    );
    const hasUploadSection = isDomesticShipment || uploadData.length > 0;

    const sizeError = fileSizeError != '';
    switch (mode) {
      case ShipmentMode.AddItem:
        return !(items && items.length > 0);
      case ShipmentMode.AddAddress:
        return !address;
      case ShipmentMode.ShippingDetails:
        return !(
          shippingDate &&
          cartonList.length > 0 &&
          !invalidShippingDetails &&
          hasUploadSection &&
          !sizeError
        );
      default:
        return false;
    }
  };
  return (
    <Stack
      space={0}
      direction="horizontal"
      justifyContent="space-between"
      className="ShipmentFormNavigation"
      alignItems="center"
    >
      <OrderNavBreadcrumbs />
      {mode === ShipmentMode.Success || mode === ShipmentMode.Error ? (
        <Button
          className="ShipmentFormNavigation__next-button"
          secondary
          chip
          to={hrefFor.OrdersPage()}
        >
          Return To POs
        </Button>
      ) : (
        <Stack space={4} direction="horizontal">
          <Button
            className="ShipmentFormNavigation__back-button"
            secondary
            chip
            onClick={handleClick}
          >
            Back
          </Button>
          {mode == ShipmentMode.Review || mode == ShipmentMode.Loading ? (
            <Button
              className="ShipmentFormNavigation__submit-button"
              secondary
              chip
              onClick={handleClick}
              disabled={mode === ShipmentMode.Loading}
            >
              Submit
            </Button>
          ) : (
            <Button
              className="ShipmentFormNavigation__next-button"
              secondary
              chip
              onClick={handleClick}
              disabled={handleDisableState()}
            >
              Next
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
};
