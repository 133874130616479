import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, Text, Stack } from '@moda/om';
import { Document, Page, pdfjs, DocumentProps } from 'react-pdf';
import { useAuth0 } from '@auth0/auth0-react';
import FileSaver from 'file-saver';
import { CONFIG } from '../../config';

import './PackingListPage.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  withCredentials: true
};

export const PackingListPage: React.FC = () => {
  const [totalPage, setTotalPage] = useState<number | null>(null);
  const [authToken, setAuthToken] = useState<string>();
  const { getAccessTokenSilently, error } = useAuth0();
  const { asnsNumber } = useParams<{ asnsNumber: string }>();
  const url = `${CONFIG.API}/asns/${asnsNumber}`;

  useEffect(() => {
    getAccessTokenSilently().then(Auth => setAuthToken(Auth));
  }, [getAccessTokenSilently, setAuthToken]);

  const onDocumentLoadSuccess: DocumentProps['onLoadSuccess'] = pdf => {
    const { numPages } = pdf;
    if (totalPage) return;
    setTotalPage(numPages);
    pdf.saveDocument().then(value => {
      const blob = new Blob([value], { type: 'application/pdf' });
      FileSaver.saveAs(blob, `${asnsNumber}`);
    });
  };

  return (
    <div className="PackingListPage">
      <div className="PackingListPage__pdf-wrapper">
        <Document
          file={url}
          loading={<Loading />}
          onLoadSuccess={onDocumentLoadSuccess}
          error={
            <Stack space={0} alignItems="center" className="PackingListPage__error">
              <img src="/pdf-error.svg" alt="error" />
              <Text treatment="h3">Oops! We couldn't find your file.</Text>
            </Stack>
          }
          options={{
            ...options,
            httpHeaders: {
              'Content-Type': 'application/pdf',
              Authorization: `Bearer ${authToken}`
            }
          }}
        >
          {Array.from(new Array(totalPage), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              scale={1.5}
              pageNumber={index + 1}
              className="PackingListPage__pdf-page"
            />
          ))}
        </Document>
        {error && (
          <Stack space={0} alignItems="center" className="PackingListPage__error">
            <img src="/pdf-error.svg" alt="error" />
            <Text treatment="h3">You\'re not authorized to view this file.</Text>
          </Stack>
        )}
      </div>
    </div>
  );
};
