export const validations = {
  company: {
    required: {
      message: 'Please enter company name',
      value: true
    }
  },
  firstName: {
    required: {
      message: 'Please enter first name',
      value: true
    }
  },
  lastName: {
    required: {
      message: 'Please enter last name',
      value: true
    }
  },
  addressLine1: {
    required: {
      message: 'Please enter an address',
      value: true
    }
  },
  city: {
    required: {
      message: 'Please enter a city',
      value: true
    }
  },
  state: {
    required: {
      message: 'Please select or enter a state/region',
      value: true
    }
  },
  postalCode: {
    required: {
      message: 'Please enter a zip/postal code',
      value: true
    }
  },
  phone: {
    required: {
      message: 'Phone must be at least 7 digits',
      value: true
    }
  }
};
