import '@ungap/with-resolvers';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { loadableReady } from '@loadable/component';
import { loadSegment } from '@moda/portal-astrarium';
import { App } from '../App';
import { isDevelopment } from '../lib/isEnv';
import { CONFIG } from '../config';

const history = createBrowserHistory();
const el = document.getElementById('root');

if (document.cookie.includes('_evidon_suppress_notification_cookie')) {
  loadSegment(CONFIG.SEGMENT_WRITE_KEY, {
    integrations: {
      All: false,
      'Segment.io': true,
      'Google Analytics': true
    }
  });
} else {
  loadSegment(CONFIG.SEGMENT_WRITE_KEY, { integrations: { All: true } });
}

const onRedirectCallback = (appState?: AppState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

const ClientSideApp = (
  <Router history={history}>
    <Auth0Provider
      domain={CONFIG.DOMAIN}
      clientId={CONFIG.API_APPLICATION_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/orders`,
        audience: CONFIG.AUDIENCE,
        scope: CONFIG.SCOPES
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </Auth0Provider>
  </Router>
);
const init = () => {
  if (!el) throw new Error('#root element not found');

  isDevelopment()
    ? ReactDOM.createRoot(el).render(ClientSideApp)
    : loadableReady(() => ReactDOM.createRoot(el).render(ClientSideApp));
};

init();

export default init;
