import React from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Text, Button, ControlLink, Input } from '@moda/om';
import HandshakeIcon from '@moda/icons/handshake-48';
import { CONFIG } from '../../config';
import './TermsAndConditionsPage.scss';

export const TermsAndConditionsPage: React.FC = () => {
  const search = useLocation().search;
  const vendorId = new URLSearchParams(search).get('vendor_id') || '';
  const loginDomain = new URLSearchParams(search).get('auth0_domain') || '';
  const loginState = new URLSearchParams(search).get('state') || '';

  return (
    <div className="TermsAndConditionsPageModal">
      <div className="TermsAndConditionsPage__modal">
        <div className="TermsAndConditionsPage__modal-content">
          <Stack space={6} className="TermsAndConditionsPage__unconfirmed">
            <div className="TermsAndConditionsPage__mo-modal-header">
              <HandshakeIcon className="TermsAndConditionsPage__handshake-icon" />
            </div>
            <Text treatment="h4" className="TermsAndConditionsPage__mo-modal-body">
              Create an account on the Moda Operandi Vendor Portal.
            </Text>
            <div>
              <Text treatment="h6" className="TermsAndConditionsPage__confirm-msg">
                By clicking the "I Agree" button below, you confirm you have reviewed and agree to
                Moda Operandi's Terms and Conditions and Operating Guidelines on behalf of an entity
                entity that you represent, and you further confirm that you have the requisite right
                and authority to enter into such agreements on its behalf.
              </Text>
              <p>
                <ControlLink
                  href={`${CONFIG.S3}/Moda-Operandi-Vendor-Terms-Conditions-2020.pdf`}
                  rel="noopener noreferrer"
                  target="_blank"
                  underlined
                >
                  Operating Guidelines
                </ControlLink>{' '}
                and{' '}
                <ControlLink
                  href={`${CONFIG.S3}/Moda-Operandi-Vendor-Terms-Conditions-2020.pdf`}
                  rel="noopener noreferrer"
                  target="_blank"
                  underlined
                >
                  Terms &amp; Conditions
                </ControlLink>
              </p>
            </div>
            <form
              className="TermsAndConditionsPage__form"
              method="post"
              action={`https://${loginDomain}/continue?state=${loginState}`}
            >
              <div className="TermsAndConditionsPage__mo-modal-footer">
                <Input hidden name="vendor_id" value={vendorId} />
                <Button type="submit" secondary className="TermsAndConditionsPage__agree-button">
                  I Agree
                </Button>
              </div>
            </form>
          </Stack>
        </div>
      </div>
    </div>
  );
};
