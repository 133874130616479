import React from 'react';
import {
  Stack,
  Divider,
  Text,
  ControlLink,
  Textarea,
  Label,
  Button,
  Expandable,
  Breakpoint
} from '@moda/om';
import PrevIcon from '@moda/icons/prev-16';
import { PurchaseOrderChangeRequest } from '../../../../types/data';
import { Mode as PurchaseOrderReturnPanelMode } from '../PurchaseOrderReturnPanel';
import { ShippingDateSelector } from '../../../ShippingDateSelector';
import './PurchaseOrderReturnPanelPOChange.scss';

interface Props {
  heading: string;
  formData: PurchaseOrderChangeRequest[];
  onCloseForm: () => void;
  mode: PurchaseOrderReturnPanelMode;
  handleDateSelect: (date: Date | null, label?: string) => void;
  handlePOInformationChange: (name: string, value: string) => void;
}

export const PurchaseOrderReturnPanelPOInfoChange: React.FC<Props> = ({
  mode,
  heading,
  formData,
  onCloseForm,
  handleDateSelect,
  handlePOInformationChange
}) => {
  return (
    <div className="PurchaseOrderReturnPanelPOChange">
      <div>
        <Text className="PurchaseOrderReturnPanelPOChange__heading" treatment="h5" family="serif">
          Enter Change(s) {heading}
        </Text>
        <div className="PurchaseOrderReturnPanelPOChange__back-link-wrapper">
          <ControlLink
            className="PurchaseOrderReturnPanelPOChange__back-link"
            onClick={onCloseForm}
            rel="noopener noreferrer"
            target="_blank"
            underlined={false}
          >
            <PrevIcon />
            <Text color="cornflower-blue" treatment="h6" family="serif">
              Back
            </Text>
          </ControlLink>{' '}
        </div>
        <Divider />
        <div className="PurchaseOrderReturnPanelPOChange">
          <div className="PurchaseOrderReturnPanelPOChange__fields">
            {mode === PurchaseOrderReturnPanelMode.EditPo && (
              <>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Cancellation
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="cancellation"
                        placeholder="Enter the reason for cancellation"
                        rows={3}
                        defaultValue={
                          formData.find(poInfo => poInfo.changeType == 'Cancellation')?.request ||
                          ''
                        }
                        onChange={value => handlePOInformationChange('Cancellation', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Delivery Window
                        </Label>
                      }
                      expanded
                    >
                      <Breakpoint gt="lg">
                        <Stack space={2} justifyContent="space-between" direction="horizontal">
                          <ShippingDateSelector
                            className="PurchaseOrderReturnPanelPOChange__date-selector"
                            handleDateSelect={handleDateSelect}
                            name="startShipDate"
                            label="Start Ship Date"
                            defaultValue={
                              formData.find(poInfo => poInfo.changeType == 'Start Ship Date')
                                ?.request || ''
                            }
                          />
                          <ShippingDateSelector
                            className="PurchaseOrderReturnPanelPOChange__date-selector"
                            handleDateSelect={handleDateSelect}
                            name="inWarehouseDate"
                            label="In Warehouse Date"
                            defaultValue={
                              formData.find(poInfo => poInfo.changeType == 'In Warehouse Date')
                                ?.request || ''
                            }
                          />
                        </Stack>
                      </Breakpoint>
                      <Breakpoint lt="lg">
                        <Stack space={2} justifyContent="space-between" direction="vertical">
                          <ShippingDateSelector
                            className="PurchaseOrderReturnPanelPOChange__date-selector"
                            handleDateSelect={handleDateSelect}
                            name="startShipDate"
                            label="Start Ship Date"
                            defaultValue={
                              formData.find(poInfo => poInfo.changeType == 'Start Ship Date')
                                ?.request || ''
                            }
                          />
                          <ShippingDateSelector
                            className="PurchaseOrderReturnPanelPOChange__date-selector"
                            handleDateSelect={handleDateSelect}
                            name="inWarehouseDate"
                            label="In Warehouse Date"
                            defaultValue={
                              formData.find(poInfo => poInfo.changeType == 'In Warehouse Date')
                                ?.request || ''
                            }
                          />
                        </Stack>
                      </Breakpoint>
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Size Change
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="size"
                        placeholder="Enter new size scale/range"
                        rows={3}
                        defaultValue={
                          formData.find(poInfo => poInfo.changeType == 'Size Change')?.request || ''
                        }
                        onChange={value => handlePOInformationChange('Size Change', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">Currency</Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="currency"
                        placeholder="Enter the currency you would like to be paid in (e.g., GBP, EUR, USD)"
                        rows={3}
                        defaultValue={
                          formData.find(poInfo => poInfo.changeType == 'Currency')?.request || ''
                        }
                        onChange={value => handlePOInformationChange('Currency', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Payment Terms
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="payment"
                        placeholder="Enter new payment terms"
                        rows={3}
                        defaultValue={
                          formData.find(poInfo => poInfo.changeType == 'Payment Terms')?.request ||
                          ''
                        }
                        onChange={value => handlePOInformationChange('Payment Terms', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Shipment Terms
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="shipment"
                        placeholder="Enter new shipment terms (e.g., Exworks, DDP, Landed)"
                        rows={3}
                        defaultValue={
                          formData.find(poInfo => poInfo.changeType == 'Shipment Terms')?.request ||
                          ''
                        }
                        onChange={value => handlePOInformationChange('Shipment Terms', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">Other</Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="other"
                        placeholder="Enter any other changes that apply to all styles"
                        rows={3}
                        defaultValue={
                          formData.find(poInfo => poInfo.changeType == 'Other')?.request || ''
                        }
                        onChange={value => handlePOInformationChange('Other', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
              </>
            )}
          </div>
          <div className="PurchaseOrderReturnPanelPOChange__submit-section">
            <Divider />
            <Stack space={4} className="PurchaseOrderReturnPanelPOChange__submit">
              <Button
                secondary
                className="PurchaseOrderReturnPanelPOChange__submit-button"
                disabled={false}
                onClick={onCloseForm}
              >
                Done
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};
