import React, { useCallback, useReducer, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Loading, SearchInput, Stack } from '@moda/om';
import { ShipmentFormNavigation } from '../../components/ShipmentFormNavigation';
import { ProgressBar } from '../../components/ProgressBar';
import { ShipmentReview } from '../../components/ShipmentReview';
import { ShipmentDetails } from '../../components/ShipmentDetails';
import { SelectionPanel } from '../../components/SelectionPanel';
import { ShippingAddressForm } from '../../components/ShippingAddress';
import { Address } from '../../types/address';
import { FulfillAllModal } from '../../components/FulfillAllModal';
import { useFetchPurchaseOrder, useFetchStyles } from '../../api/purchaseOrders';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import {
  Cartons,
  Style,
  CartonGroup,
  SingleCarton,
  ShipmentDetailsData,
  Sku,
  SelectedItemsInPage,
  Items,
  ShippingInformationData,
  SkuList
} from '../../types/data';
import { ShipmentConfirmationModal } from '../../components/ShipmentConfirmationModal';
import { hrefFor } from '../../routers';
import { useShipment } from '../../hooks/useShipment/useShipment';
import { useShippingFormTracking } from '../../hooks/useShippingFormTracking';

import './ShipmentPage.scss';

export type State = {
  mode: Mode;
  items?: SelectedItemsInPage[];
  address?: Address;
  shippingDetails?: ShipmentDetailsData;
};

export enum Mode {
  AddItem,
  AddAddress,
  ShippingDetails,
  Review,
  Loading,
  Success,
  Error
}

export enum FulfillMode {
  Resting,
  FulfillAll,
  Confirming,
  Confirmed
}

type Action =
  | { type: 'ADD_ITEM' }
  | { type: 'ADD_ADDRESS'; payload: { items?: SelectedItemsInPage[]; address?: Address } }
  | {
      type: 'SHIPPING_DETAILS';
      payload: { items?: SelectedItemsInPage[]; address?: Address };
    }
  | {
      type: 'REVIEW';
      payload: {
        shippingDetails?: {
          shipmentDate: string;
          cartonList: CartonGroup;
          uploadData?: File[];
          shippingInformation?: ShippingInformationData;
        };
        items?: SelectedItemsInPage[];
        address?: Address;
      };
    }
  | { type: 'LOADING' }
  | { type: 'SUCCESS' }
  | { type: 'ERROR' };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ADD_ITEM':
      return { mode: Mode.AddItem };
    case 'ADD_ADDRESS':
      return {
        mode: Mode.AddAddress,
        items: action.payload.items,
        address: action.payload.address
      };
    case 'SHIPPING_DETAILS':
      return { mode: Mode.ShippingDetails, address: action.payload.address, items: state.items };
    case 'REVIEW':
      return {
        mode: Mode.Review,
        items: state.items,
        address: state.address,
        shippingDetails: action.payload.shippingDetails
      };
    case 'LOADING':
      return { mode: Mode.Loading };
    case 'SUCCESS':
      return { mode: Mode.Success };
    case 'ERROR':
      return { mode: Mode.Error };
    default:
      return state;
  }
};

type ButtonType = 'NEXT' | 'BACK' | 'SUBMIT' | 'RETURN TO PO';

export const initialState = {
  mode: Mode.AddItem,
  address: {} as Address,
  items: {} as SelectedItemsInPage[],
  shippingDetails: {} as ShipmentDetailsData
};

export const ShipmentPage: React.FC = () => {
  const { poNumber } = useParams<{ poNumber: string }>();
  const response = useFetchStyles(poNumber);
  const { stylesData, errors, loading, auth } = response;
  const purchaseOrder = useFetchPurchaseOrder(poNumber);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { mode } = state;
  const [fulfillMode, setFulfillMode] = useState(FulfillMode.Resting);
  const [selectedItemsInPage, setSelectedItemsInPage] = useState<SelectedItemsInPage[]>([]);
  const [items, setItems] = useState<Items[]>([]);
  const [skuForm, setSkuForm] = useState<SkuList[]>([]);
  const [address, setAddress] = useState<Address>();
  const [shipmentDate, setShipmentDate] = useState('');
  const [fileSizeMb, setFileSizeMb] = useState<number>(0);
  const [fileSizeError, setFileSizeError] = useState('');
  const [cartons, setCartons] = useState<Cartons[]>([]);
  const [search, setSearch] = useState('');
  const [cartonList, setCartonList] = useState<SingleCarton[]>([]);
  const [packagingList, setPackagingList] = useState<CartonGroup>({
    unit: { mass: 'lb', dimension: 'in' },
    cartons: []
  });
  const [uploadData, setUploadData] = useState<File[]>([]);
  const [shippingInformationData, setShippingInformationData] = useState<ShippingInformationData>();
  const history = useHistory();
  const { successShipment, shipmentError, shipmentloading, handleFormSubmit } = useShipment(
    state,
    items,
    purchaseOrder,
    shipmentDate,
    poNumber,
    auth
  );

  useShippingFormTracking(purchaseOrder?.data);
  useEffect(() => {
    if (shipmentloading) {
      dispatch({ type: 'LOADING' });
    }
    if (successShipment) {
      dispatch({ type: 'SUCCESS' });
    }
    if (shipmentError) {
      dispatch({ type: 'ERROR' });
    }
  }, [successShipment, shipmentError, shipmentloading]);

  const handleClick = (buttonType: ButtonType) => {
    switch (mode) {
      case Mode.AddItem:
        buttonType === 'NEXT'
          ? dispatch({
              type: 'ADD_ADDRESS',
              payload: { items: selectedItemsInPage, address: address }
            })
          : history.push(hrefFor.OrdersPage());
        break;
      case Mode.AddAddress:
        buttonType === 'NEXT'
          ? dispatch({ type: 'SHIPPING_DETAILS', payload: { address } })
          : dispatch({ type: 'ADD_ITEM' });
        break;
      case Mode.ShippingDetails:
        buttonType === 'NEXT'
          ? dispatch({
              type: 'REVIEW',
              payload: {
                shippingDetails: {
                  shipmentDate,
                  cartonList: packagingList,
                  uploadData,
                  shippingInformation: shippingInformationData
                }
              }
            })
          : dispatch({
              type: 'ADD_ADDRESS',
              payload: { items: selectedItemsInPage, address: address }
            });
        break;
      case Mode.Review:
        buttonType === 'SUBMIT'
          ? handleFormSubmit()
          : dispatch({ type: 'SHIPPING_DETAILS', payload: { address } });
        break;
      case Mode.Loading:
        buttonType === 'RETURN TO PO' ? dispatch({ type: 'LOADING' }) : null;
        break;
      case Mode.Success:
        buttonType === 'RETURN TO PO' ? dispatch({ type: 'SUCCESS' }) : null;
        break;
      case Mode.Error:
        buttonType === 'RETURN TO PO' ? dispatch({ type: 'ERROR' }) : null;
        break;
      default:
        dispatch({ type: 'ADD_ITEM' });
        break;
    }
  };

  const handleOnSave = (address: Address) => {
    setAddress(address);
    dispatch({ type: 'SHIPPING_DETAILS', payload: { address } });
  };

  const getMaxQuantity = (sku: Sku) => {
    return (sku && sku.quantityRemaining > 0 && sku.quantityRemaining) || 0;
  };

  const selectableSkus = (style: Style) => {
    return style.skus.filter((sku: Sku) => getMaxQuantity(sku) > 0);
  };

  const handleFulfillment = () => {
    setSelectedItemsInPage([]);
    setSearch('');
    setSkuForm([]);
    setItems([]);
    const selectedItemsInPage: SelectedItemsInPage[] = [];
    const items: Items[] = [];
    const selectedSkuList: SkuList[] = [];
    setFulfillMode(FulfillMode.Confirmed);
    const validStyles = stylesData?.filter((style: Style) => selectableSkus(style).length > 0);
    validStyles?.forEach((style: Style) => {
      const selectedSkus: SkuList[] = [];
      const availableSkus = selectableSkus(style);
      availableSkus?.forEach(
        (sku: Sku) => {
          selectedSkus.push({
            styleNumber: style.styleNumber,
            skuSize: sku.skuSize,
            quantity: getMaxQuantity(sku).toString(),
            skuNumber: sku.skuNumber
          });
          items.push({
            sku: sku.skuNumber,
            quantity: getMaxQuantity(sku)
          });
          selectedSkuList.push({
            styleNumber: style.styleNumber,
            skuSize: sku.skuSize,
            quantity: getMaxQuantity(sku).toString(),
            skuNumber: sku.skuNumber,
            error: ''
          });
        },
        selectedItemsInPage.push({
          styleName: style.styleName,
          styleNumber: style.styleNumber,
          vendorColor: style.vendorColor,
          imageUrl: style.imageUrl || '/item.png',
          skus: selectedSkus
        })
      );
    });
    setSelectedItemsInPage(selectedItemsInPage);
    setItems(items);
    setSkuForm(selectedSkuList);
    dispatch({ type: 'ADD_ADDRESS', payload: { items: selectedItemsInPage, address: address } });
  };

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleSearchClear = useCallback(() => setSearch(''), [setSearch]);

  const isDomesticShipment = address?.countryShortCode === 'USA' ? true : false;

  if (loading) return <Loading />;
  if (errors) return <ErrorPage />;

  return (
    <Stack space={6} className="ShipmentPage">
      <ShipmentFormNavigation
        poNumber={poNumber}
        onClickButton={handleClick}
        mode={mode}
        items={selectedItemsInPage}
        cartonList={cartonList}
        shippingDate={shipmentDate}
        address={state.address}
        uploadData={uploadData}
        isDomesticShipment={isDomesticShipment}
        fileSizeError={fileSizeError}
      />
      {mode === Mode.Success || shipmentloading || shipmentError ? (
        <ShipmentConfirmationModal
          shipmentError={shipmentError}
          shipmentloading={shipmentloading}
        />
      ) : (
        <ProgressBar mode={mode} />
      )}
      {mode == Mode.AddItem && (
        <Stack space={2} direction="horizontal" className="ShipmentPage__search">
          <SearchInput
            className="ShipmentPage__input"
            placeholder="Search by style number"
            onChange={term => handleSearch(term)}
            onClear={handleSearchClear}
          />
          <Button
            className="ShipmentPage__fulfill-button"
            secondary
            onClick={() => setFulfillMode(FulfillMode.Confirming)}
          >
            Fulfill All
          </Button>
        </Stack>
      )}
      <div className="ShipmentPage__main">
        {mode == Mode.AddItem && (
          <SelectionPanel
            search={search}
            styles={stylesData}
            onClickButton={handleClick}
            setSelectedItemsInPage={setSelectedItemsInPage}
            selectedItemsInPage={selectedItemsInPage}
            setItems={setItems}
            skuForm={skuForm}
            setSkuForm={setSkuForm}
            items={items}
          />
        )}
        {mode == Mode.AddAddress && (
          <ShippingAddressForm
            onSave={handleOnSave}
            poShipTo={purchaseOrder?.data?.shipToAddress}
            poShipFrom={state.address}
          />
        )}
        {mode == Mode.ShippingDetails && (
          <ShipmentDetails
            setShipmentDate={setShipmentDate}
            setCartons={setCartons}
            cartonList={cartonList}
            packagingList={packagingList}
            shippingDate={shipmentDate}
            setPackagingList={setPackagingList}
            setCartonList={setCartonList}
            cartons={cartons}
            uploadData={uploadData}
            setUploadData={setUploadData}
            shippingInformation={shippingInformationData}
            fileSizeMb={fileSizeMb}
            fileSizeError={fileSizeError}
            setFileSizeMb={setFileSizeMb}
            setFileSizeError={setFileSizeError}
            setShippingInformationData={setShippingInformationData}
            isDomesticShipment={isDomesticShipment}
            isLanded={
              purchaseOrder.data?.deliveryTerms.toUpperCase() === 'LANDED (DDP) MODA OPERANDI' ||
              purchaseOrder.data?.deliveryTerms.toUpperCase() === 'MODA VAN'
            }
          />
        )}
        {mode == Mode.Review && (
          <ShipmentReview
            poShipTo={purchaseOrder?.data?.shipToAddress}
            poShipFrom={state.address}
            poItems={state.items}
            shippingDetails={state.shippingDetails}
            editItems={() => dispatch({ type: 'ADD_ITEM' })}
            editAddress={() =>
              dispatch({ type: 'ADD_ADDRESS', payload: { address, items: selectedItemsInPage } })
            }
            editShipment={() => dispatch({ type: 'SHIPPING_DETAILS', payload: { address } })}
          />
        )}
      </div>
      <Stack space={0}>
        {fulfillMode === FulfillMode.Confirming && (
          <FulfillAllModal
            mode={fulfillMode}
            onClose={() => setFulfillMode(FulfillMode.Resting)}
            onConfirm={handleFulfillment}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ShipmentPage;
