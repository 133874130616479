import React, { useCallback, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { Button, SearchInput, Stack, Text, LoadingBalls } from '@moda/om';
import { deferUntilNewTitle, usePrevious } from '@moda/portal-stanchions';
import { tc } from '../../lib/trackingContext';
import { hrefFor } from '../../routers';
import { PurchaseOrder } from '../../hooks/usePoList';
import { PO_STATUS_LABELS } from '../../constants/poStatuses';
import { ErrorPage } from '../../pages/ErrorPage/ErrorPage';
import { useGetAuthToken } from '../../hooks/useGetAuthToken';
import { useFetchPurchaseOrders } from '../../api/purchaseOrders';

import './PurchaseOrders.scss';

const DELAY_MS = 300;

enum Mode {
  Confirmed,
  Unconfirmed
}

export const PurchaseOrders: React.FC = () => {
  const [mode, setMode] = useState(Mode.Unconfirmed);
  const [poNumber, setPoNumber] = useState('');
  const [loading, setLoading] = useState<boolean>();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [scrollLoading, setScrollLoading] = useState(false);
  const previousMode = usePrevious(mode);
  const previousPage = usePrevious(page);
  const previousSearch = usePrevious(search);
  const getAuthToken = useGetAuthToken();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[] | []>([]);

  const state = mode == Mode.Unconfirmed ? 'Unconfirmed' : 'Confirmed';

  const {
    orders,
    errors,
    loading: useFetchPurchaseOrdersLoading
  } = useFetchPurchaseOrders({
    page,
    search,
    state,
    mode,
    previousSearch,
    previousPage,
    setPurchaseOrders
  });

  useEffect(() => {
    if (!loading) {
      deferUntilNewTitle(() => {
        tc.track('Purchase Orders Dashboard Viewed');
      });
    }
  }, [loading]);

  const showPoList = useCallback(
    (mode: Mode) => {
      if (previousMode != mode) {
        setLoading(true);
        setMode(mode);
        setPage(0);
        setPurchaseOrders([]);
        setLoading(false);
      }
    },
    [previousMode]
  );

  const loadMoreOrders = useCallback(() => {
    if (scrollLoading) return;
    setPage(page => page + 1);
    setScrollLoading(true);
    setTimeout(() => {
      setScrollLoading(false);
    }, DELAY_MS);
  }, [scrollLoading]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight &&
      hasMoreData
    )
      loadMoreOrders();
  }, [hasMoreData, loadMoreOrders]);

  const shouldShowData = useCallback(() => {
    return previousMode == mode;
  }, [mode, previousMode]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (orders && page < orders?.totalPages - 1) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [page, search, mode, previousMode, previousSearch, getAuthToken, orders, previousPage]);

  const formatDate = useCallback((date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }, []);

  const disabledCreateShipment = (order: PurchaseOrder) =>
    order.status == 'Fully_Received' || order.status == 'Billed' || order.hasRequestedMaxQty;

  const onClickHandler = useCallback((poNumber: React.SetStateAction<string>) => {
    setPoNumber(poNumber);
  }, []);

  const handleSearch = useCallback(
    (value: string) => {
      setPage(0);
      setSearch(value);
      tc.track('Purchase Order Searched', { addToScopeOnce: { searchString: value } });
    },
    [setSearch]
  );

  const formatStatus = useCallback((text: string) => {
    return PO_STATUS_LABELS.filter(label => label.statusName == text)[0].displayText;
  }, []);

  const handleSearchClear = useCallback(() => setSearch(''), [setSearch]);

  if (poNumber) {
    return <Redirect to={hrefFor.OrderDetailPage({ poNumber })} />;
  }

  if (errors) throw errors;

  return (
    <Stack space={6} className="PurchaseOrders">
      <Stack
        space={1}
        direction="horizontal"
        justifyContent="space-between"
        className="PurchaseOrders__header"
      >
        <Text treatment="h4" color="fog">
          Purchase Orders (POs) Dashboard
        </Text>
        <div className="PurchaseOrders__search-container">
          <SearchInput
            className="PurchaseOrders__search-input"
            aria-label="Search"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            onClear={handleSearchClear}
          />
        </div>
      </Stack>
      <div className="PurchaseOrders__navigation">
        <div className="PurchaseOrders__arrow-bar">
          <button
            onClick={() => showPoList(Mode.Unconfirmed)}
            className={classNames('PurchaseOrders__item', {
              'PurchaseOrders__item--active': mode === Mode.Unconfirmed
            })}
          >
            <span>Unconfirmed POs</span>
            <span className="PurchaseOrders__arrow" />
          </button>
          <button
            onClick={() => showPoList(Mode.Confirmed)}
            className={classNames('PurchaseOrders__item', {
              'PurchaseOrders__item--active': mode === Mode.Confirmed
            })}
          >
            <span>Confirmed POs</span>
          </button>
        </div>
      </div>
      <div className="ScrollDiv">
        <table className="PurchaseOrders__orders-table">
          <thead className="PurchaseOrders__thead">
            <tr className="PurchaseOrders__row">
              <th className="PurchaseOrders__row-header">PO Number</th>
              <th className="PurchaseOrders__row-header">Total Units</th>
              <th className="PurchaseOrders__row-header">Total WS Amount</th>
              <th className="PurchaseOrders__row-header">Shipment Terms</th>
              <th className="PurchaseOrders__row-header">Start Ship Date</th>
              <th className="PurchaseOrders__row-header">In Warehouse Date</th>
              <th className="PurchaseOrders__row-header">Status</th>
              {mode == Mode.Confirmed && <th className="PurchaseOrders__row-header">Actions</th>}
            </tr>
          </thead>
          {useFetchPurchaseOrdersLoading && (
            <tbody>
              <tr>
                <td colSpan={8}>
                  <LoadingBalls />
                </td>
              </tr>
            </tbody>
          )}
          {shouldShowData() && orders && orders.numberOfElements > 0 && (
            <tbody className="PurchaseOrders__tbody">
              {purchaseOrders.map((purchaseOrder: PurchaseOrder, index: number) => (
                <tr
                  className="PurchaseOrders__row"
                  key={index}
                  onClick={() => onClickHandler(purchaseOrder.poNumber)}
                >
                  <td className="PurchaseOrders__row-data">{purchaseOrder.poNumber}</td>
                  <td className="PurchaseOrders__row-data">{purchaseOrder.totalQuantity}</td>
                  <td className="PurchaseOrders__row-data">
                    {purchaseOrder.totalWholesalePriceFormatted}
                  </td>
                  <td className="PurchaseOrders__row-data">{purchaseOrder.deliveryTerms}</td>
                  <td className="PurchaseOrders__row-data">
                    {formatDate(purchaseOrder.deliveryStart)}
                  </td>
                  <td className="PurchaseOrders__row-data">
                    {formatDate(purchaseOrder.deliveryEnd)}
                  </td>
                  <td className="PurchaseOrders__row-data">
                    <Button
                      className={`PurchaseOrders__status ${purchaseOrder.status}`}
                      chip
                      disabled
                    >
                      {formatStatus(purchaseOrder.status)}
                    </Button>
                  </td>
                  {mode == Mode.Confirmed && (
                    <td className="PurchaseOrders__row-data">
                      <Button
                        className={classNames('PurchaseOrders__create-shipment', {
                          'PurchaseOrders__create-shipment--disabled':
                            disabledCreateShipment(purchaseOrder)
                        })}
                        to={hrefFor.ShipmentPage({
                          poNumber: purchaseOrder.poNumber
                        })}
                        disabled={disabledCreateShipment(purchaseOrder)}
                      >
                        Create Shipment
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
          {!loading && orders?.numberOfElements == 0 && <ErrorPage />}
        </table>
      </div>
    </Stack>
  );
};
