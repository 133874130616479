import React from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Breadcrumb, Text } from '@moda/om';
import { hrefFor } from '../../routers';
import './OrderNavBreadcrumbs.scss';

export const OrderNavBreadcrumbs: React.FC = () => {
  const { poNumber } = useParams<{ poNumber: string }>();

  return (
    <Breadcrumbs className="OrderNavBreadcrumbs">
      <Breadcrumb
        className="OrderNavBreadcrumbs__title-breadcrumb"
        to={hrefFor.OrdersPage()}
        underlined={false}
      >
        <Text treatment="h5">Purchase Orders</Text>
      </Breadcrumb>
      <Breadcrumb className="OrderNavBreadcrumbs__po-number-breadcrumb">
        <Text treatment="h5">{poNumber}</Text>
      </Breadcrumb>
    </Breadcrumbs>
  );
};
