import React from 'react';
import { Stack, Text, Loading } from '@moda/om';
import SuccessIcon from '@moda/icons/success-48';
import './ShipmentConfirmationModal.scss';

interface Props {
  shipmentError: string;
  shipmentloading: boolean;
}

export const ShipmentConfirmationModal: React.FC<Props> = ({ shipmentError, shipmentloading }) => {
  return (
    <div className="ShipmentConfirmationModal">
      <Stack space={5} className="ShipmentConfirmationModal__modal-content" alignItems="center">
        {shipmentloading ? (
          <Loading />
        ) : shipmentError ? (
          <Text color="code-red" treatment="h5">
            {shipmentError}
          </Text>
        ) : (
          <>
            <SuccessIcon className="ShipmentConfirmationModal__success-icon" />
            <Text treatment="h6" family="serif">
              Thank you!
            </Text>
            <Text treatment="h6" family="serif">
              Your shipping authorization request was successfully submitted. We'll reach out with
              further shipping instructions soon. Please do not release shipments until approval
              from us.
            </Text>
          </>
        )}
      </Stack>
    </div>
  );
};
