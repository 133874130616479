import React, { useCallback, useReducer, useState } from 'react';
import { Button, Stack, Overlay, Text, LoadingBalls } from '@moda/om';
import { useForm, FormValues } from '@moda/portal-stanchions';
import loadable from '@loadable/component';
import { CONFIG } from '../../../config';
import { Address, ShipToAddress } from '../../../types/address';
import { reportError } from '../../../lib/reportError';
import { ShippingAddressFormFields } from './ShippingAddressFormFields';
import { validations } from './validations';

import './ShippingAddressForm.scss';

interface Props {
  poShipTo: ShipToAddress | undefined;
  poShipFrom: Address | undefined;
  onSave: (address: Address) => void;
}

enum Mode {
  Resting,
  Validating,
  Saving
}

type State = {
  mode: Mode;
  attributesToValidate?: Address;
  inputAddress?: Address;
  suggestedAddress?: Address;
};

type Action =
  | {
      type: 'START_VALIDATING';
      payload: Record<string, Address | undefined>;
    }
  | { type: 'STOP_VALIDATING' }
  | { type: 'START_SAVING' }
  | { type: 'STOP_SAVING' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'START_VALIDATING':
      return {
        mode: Mode.Validating,
        inputAddress: action.payload.address,
        suggestedAddress: action.payload.suggestedAddress
      };
    case 'STOP_VALIDATING':
      return { mode: Mode.Resting };
    case 'START_SAVING':
      return { mode: Mode.Saving };
    case 'STOP_SAVING':
      return { mode: Mode.Resting };
    default:
      return state;
  }
};

const ShippingAddressValidationPopup = loadable(() => import('./ShippingAddressValidationPopup'), {
  resolveComponent: exports => exports.ShippingAddressValidationPopup
});

export const ShippingAddressForm: React.FC<Props> = ({ onSave, poShipTo, poShipFrom }) => {
  const [inputAddress, setInputAddress] = useState<Address>();
  const [suggestedAddress, setSuggestedAddress] = useState<Address>();
  const [errors, setErrors] = useState<Error | null>(null);
  const [state, dispatch] = useReducer(reducer, { mode: Mode.Resting });
  const { mode } = state;

  const startValidation = async (formValues: FormValues) => {
    const address = formValues as Address;
    let smartystreetAuth = `&candidates=10&match=invalid&key=${CONFIG.SMARTYSTREET_KEY}`;
    setInputAddress(address);
    const { addressLine1, addressLine2, city, state, postalCode } = address;
    if (CONFIG.NODE_ENV === 'development') {
      smartystreetAuth = `&candidates=10&match=invalid&auth-id=81ba802a-6b6c-77a2-344f-fdc412eeaa4a&auth-token=48H4bFBGA3iy7JCJXLth`;
    }
    try {
      const response = await fetch(
        `https://us-street.api.smartystreets.com/street-address?street=${encodeURIComponent(
          addressLine1
        )}&street2=${encodeURIComponent(addressLine2)}&city=${encodeURIComponent(
          city
        )}&state=${encodeURIComponent(state)}&zipcode=${encodeURIComponent(
          postalCode
        )}${smartystreetAuth}`
      );
      const data = await response.json();
      if (data && data.errors && data.errors[0]) {
        const { errors } = data;
        setErrors(errors[0]);
      } else {
        const firstAddressLine = data[0].delivery_line_1 ? decodeURI(data[0].delivery_line_1) : '';
        const secondAddressLine = data[0].delivery_line_2 ? decodeURI(data[0].delivery_line_2) : '';
        setSuggestedAddress({
          ...address,
          addressLine1: firstAddressLine,
          addressLine2: secondAddressLine,
          city: data[0].components.city_name,
          state: data[0].components.state_abbreviation,
          postalCode: data[0].components.zipcode || postalCode
        });
        dispatch({ type: 'START_VALIDATING', payload: { inputAddress, suggestedAddress } });
      }
    } catch (error) {
      reportError('START_VALIDATING_US_ADDRESS', error);
      setErrors(error as Error);
    }
  };

  const startInternationalValidation = async (formValues: FormValues) => {
    const address = formValues as Address;
    let smartystreetAuth = `&candidates=10&match=invalid&key=${CONFIG.SMARTYSTREET_KEY}`;
    setInputAddress(address);
    const { addressLine1, addressLine2, city, postalCode, countryShortCode } = address;
    if (CONFIG.NODE_ENV === 'development') {
      smartystreetAuth = `&candidates=10&match=invalid&auth-id=959ae1c2-a88c-fc6d-5083-9a283361a047&auth-token=ViZ5sxOSEEpxxmtsN8is`;
    }
    try {
      const response = await fetch(
        `https://international-street.api.smartystreets.com/verify?country=${encodeURIComponent(
          countryShortCode
        )}&address1=${encodeURIComponent(addressLine1)}&address2=${encodeURIComponent(
          addressLine2
        )}&locality=${encodeURIComponent(city)}&postal_code=${encodeURIComponent(
          postalCode
        )}${smartystreetAuth}`
      );
      const data = await response.json();
      const firstAddressLine = decodeURI(data[0].address1);
      const secondAddressLine = data[0].address2 ? decodeURI(data[0].address2) : '';
      setSuggestedAddress({
        ...address,
        addressLine1: firstAddressLine,
        addressLine2: secondAddressLine,
        city: data[0].components.locality,
        state: data[0].components.administrative_area,
        postalCode: data[0].components.postal_code || postalCode,
        countryShortCode: data[0].components.country_iso_3
      });
      dispatch({ type: 'START_VALIDATING', payload: { inputAddress, suggestedAddress } });
    } catch (error) {
      reportError('START_VALIDATING_INTERNATIONAL_ADDRESS', error);
      setErrors(error as Error);
    }
  };

  const stopValidation = useCallback(() => {
    dispatch({ type: 'STOP_VALIDATING' });
  }, []);

  const onValidation = (values: FormValues) => {
    const address = values as Address;
    address?.countryShortCode === 'USA'
      ? startValidation(values)
      : startInternationalValidation(values);
  };

  const { handleSubmit: handleSubmitForValidation, errors: validationErrors } = useForm({
    onSubmit: onValidation,
    validations
  });

  const handleSubmit = useCallback(
    (address: Address) => {
      dispatch({ type: 'START_SAVING' });
      try {
        onSave(address);
      } catch (error) {
        reportError('SUBMIT_ADDRESS', error);
      }
      dispatch({ type: 'STOP_SAVING' });
    },
    [onSave]
  );

  return (
    <Stack direction="horizontal" space={6} justifyContent="center" className="ShippingAddressForm">
      <Stack space={2}>
        <Text treatment="h6" className="ShipmentReview__heading-text">
          Ship From
        </Text>
        <form className="ShippingAddressForm__form" onSubmit={handleSubmitForValidation}>
          <Stack space={4}>
            <Stack space={4}>
              <ShippingAddressFormFields
                validationErrors={validationErrors}
                poShipFrom={poShipFrom}
              />
            </Stack>
            <Stack space={0} alignItems="flex-end">
              <Button
                className="ShippingAddressForm__next-button"
                secondary
                chip
                type="submit"
                data-testid="shipping-address-form-next"
              >
                Next
              </Button>
            </Stack>
            {typeof errors == 'object' && (
              <Text treatment="bold1" color="code-red">
                {errors?.message}
              </Text>
            )}
          </Stack>
        </form>
      </Stack>
      <Stack space={2}>
        <Text treatment="h6" className="ShipmentReview__heading-text">
          Ship To
        </Text>
        <Stack space={1} className="ShippingAddressForm__ship-to">
          <Text>{poShipTo?.firstName}</Text>
          <Text>{poShipTo?.lastName}</Text>
          <Text>{poShipTo?.addressLine1}</Text>
          <Text>{poShipTo?.addressLine2}</Text>
          <Text>
            {poShipTo?.city} {poShipTo?.state}
          </Text>
          <Text>
            {poShipTo?.countryCode} {poShipTo?.postalCode}
          </Text>
        </Stack>
      </Stack>
      {mode === Mode.Validating && inputAddress && (
        <ShippingAddressValidationPopup
          inputAddress={inputAddress}
          suggestedAddress={suggestedAddress}
          onSubmit={handleSubmit}
          onClose={stopValidation}
        />
      )}
      <Overlay show={mode === Mode.Saving}>
        <LoadingBalls />

        <Text treatment="eyebrow">Saving</Text>
      </Overlay>
    </Stack>
  );
};
