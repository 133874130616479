export const PO_STATUS_LABELS = [
  {
    statusName: 'Draft',
    displayText: 'Ready for review'
  },
  {
    statusName: 'Confirmed',
    displayText: 'Confirmed'
  },
  {
    statusName: 'Changes_Requested',
    displayText: 'Changes Requested'
  },
  {
    statusName: 'Changes_Accepted',
    displayText: 'Updated for Review'
  },
  {
    statusName: 'Receiving',
    displayText: 'Receiving'
  },
  {
    statusName: 'Fully_Received',
    displayText: 'Fully Received'
  },
  {
    statusName: 'Fully_Fulfilled',
    displayText: 'Fully Fulfilled'
  },
  {
    statusName: 'Billed',
    displayText: 'Billed'
  },
  {
    statusName: 'Revision',
    displayText: 'Revision'
  }
];

export const SHIP_TO_ADDRESS_KEYS = {
  addressLine1: 'toAddressLineOne',
  addressLine2: 'toAddressLineTwo',
  city: 'toAddressCity',
  countryCode: 'toAddressCountry',
  firstName: 'toAddressFirstName',
  lastName: 'toAddressLastName',
  postalCode: 'toAddressPostalCode',
  state: 'toAddressState'
};

export const SHIP_FROM_ADDRESS_KEYS = {
  addressLine1: 'fromAddressLineOne',
  addressLine2: 'fromAddressLineTwo',
  city: 'fromAddressCity',
  countryShortCode: 'fromAddressCountry',
  firstName: 'fromAddressFirstName',
  lastName: 'fromAddressLastName',
  postalCode: 'fromAddressPostalCode',
  state: 'fromAddressState',
  phoneNumber: 'fromAddressPhoneNumber',
  extension: 'fromAddressExtension'
};

export const POINT_OF_MANUFACTURING_KEYS = {
  addressLine1: 'pointOfManufacturingLineOne',
  addressLine2: 'pointOfManufacturingLineTwo',
  city: 'pointOfManufacturingCity',
  countryShortCode: 'pointOfManufacturingCountry',
  firstName: 'pointOfManufacturingFirstName',
  lastName: 'pointOfManufacturingLastName',
  postalCode: 'pointOfManufacturingPostalCode',
  state: 'pointOfManufacturingState',
  phoneNumber: 'pointOfManufacturingPhoneNumber',
  extension: 'pointOfManufacturingExtension'
};
