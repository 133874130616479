import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { reportError } from '../../lib/reportError';

export const useGetAuthToken = () => {
  const { getAccessTokenSilently, isLoading, error } = useAuth0();
  const getToken = useCallback(async () => {
    try {
      if (!isLoading && !error) {
        const accessToken = await getAccessTokenSilently();
        return accessToken;
      }
      if (error) {
        return error;
      }
    } catch (error) {
      reportError('GET_AUTH_TOKEN', error);
    }
  }, [error, getAccessTokenSilently, isLoading]);
  return getToken;
};
