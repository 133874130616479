import { Dictionary, keysFrom, TrackingScopes } from '@moda/portal-astrarium';

const googleAnalyticsCategories = [
  'Active Navigation',
  'Dashboard',
  'Purchase Order Detail',
  'Shipment Auth',
  'Login and Registration'
] as const;

const categoryMap: { [key in keyof typeof events]?: (typeof googleAnalyticsCategories)[number] } = {
  'Interacted with Shipment Form': 'Shipment Auth',
  'Shipment Submitted': 'Shipment Auth',
  'Shipment Error Encountered': 'Shipment Auth',
  'Signed In': 'Login and Registration',
  'Signed Out': 'Login and Registration',
  'Breadcrumb Clicked': 'Active Navigation',
  'Purchase Order Searched': 'Dashboard',
  'Purchase Order Viewed': 'Purchase Order Detail',
  'Purchase Orders Dashboard Viewed': 'Dashboard',
  'Request Changes Submitted': 'Purchase Order Detail',
  'Confirm PO Submitted': 'Purchase Order Detail'
};

const getCategory = (scopes: TrackingScopes, eventName: string) =>
  (categoryMap as Record<string, string>)[eventName] || undefined;

const definitions = {
  search_string: ['searchString'],
  purchase_number: ['order.purchaseOrderNumber'],
  status: ['order.status'],
  vendor_name: ['order.vendorName'],
  email: ['user.email'],
  first_name: ['user.firstName'],
  last_name: ['user.lastName'],
  country_code: ['user.countryCode'],
  category: ['category', getCategory]
};

const propertyGroups = {
  PurchaseOrder: keysFrom<typeof definitions>(['purchase_number', 'vendor_name', 'status']),
  SearchInfo: keysFrom<typeof definitions>(['search_string']),
  User: keysFrom<typeof definitions>(['email', 'first_name', 'last_name', 'country_code']),
  GoogleAnalytics: keysFrom<typeof definitions>(['category'])
};

const events = {
  'Interacted with Shipment Form': keysFrom<typeof propertyGroups>([
    'PurchaseOrder',
    'GoogleAnalytics'
  ]),
  'Shipment Submitted': keysFrom<typeof propertyGroups>(['PurchaseOrder', 'GoogleAnalytics']),
  'Shipment Error Encountered': keysFrom<typeof propertyGroups>([
    'PurchaseOrder',
    'GoogleAnalytics'
  ]),
  'Signed In': keysFrom<typeof propertyGroups>(['User', 'GoogleAnalytics']),
  'Signed Out': keysFrom<typeof propertyGroups>(['User', 'GoogleAnalytics']),
  'Breadcrumb Clicked': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Purchase Order Searched': keysFrom<typeof propertyGroups>(['SearchInfo']),
  'Purchase Order Viewed': keysFrom<typeof propertyGroups>(['PurchaseOrder', 'GoogleAnalytics']),
  'Purchase Orders Dashboard Viewed': keysFrom<typeof propertyGroups>(['GoogleAnalytics']),
  'Request Changes Submitted': keysFrom<typeof propertyGroups>([
    'PurchaseOrder',
    'GoogleAnalytics'
  ]),
  'Confirm PO Submitted': keysFrom<typeof propertyGroups>(['PurchaseOrder', 'GoogleAnalytics'])
};

const appendToEveryEvent = {
  srx: true,
  event_source: 'astrarium'
};

export const dictionary = new Dictionary<
  keyof typeof events,
  keyof typeof propertyGroups,
  keyof typeof definitions
>(events, propertyGroups, definitions, appendToEveryEvent);
