import { pick, reject, isNil } from 'ramda';
import { isClientSide } from '../lib/isClientSide';

const compactObject = reject(isNil);

// NOTE: DO NOT INCLUDE SECRETS + DO NOT SPECIFY SECRETS AS ENV VARIABLES
export const DEFAULTS = {
  NODE_ENV: 'production',
  API: 'https://vendor-service-prod.anr.modaoperandi.com/v1',
  S3: 'https://vendor-portal-email-info.s3.amazonaws.com',
  VENDOR_SERVICE_API_URL: 'https://vendor-service-prod.anr.modaoperandi.com',
  API_APPLICATION_ID: '',
  REDIRECT_URL: 'https://portal.modaoperandi.com/login',
  DOMAIN: 'modaoperandi.auth0.com',
  AUDIENCE: 'https://vendor-service.modaoperandi.com',
  SCOPES: 'read:po update:po create:admin delete:user',
  SIGNIN_URL: 'https://modaoperandi.auth0.com/userinfo',
  SIGNOUT_URL: '', // VP does not use signout URL directly, stub it with blank for now
  LD_CLIENT_ID: '5e82853d8c68220722c83913',
  SEGMENT_WRITE_KEY: 'K2qaYQl1rpFcqxYL4aXzywnvHefUZnfJ',
  SMARTYSTREET_KEY: '19402208121150742',
  LOG_LEVEL: 'info'
};
const keys = Object.keys(DEFAULTS) as Key[];
const filtered = pick(keys);

export type Env = typeof DEFAULTS;
export type Key = keyof Env;

// NOTE: Precedence is:
// env vars from server > env vars from compiler > defaults
export const env = {
  environment: (): Env => {
    return {
      ...DEFAULTS,
      ...compactObject(
        isClientSide()
          ? {
              ...keys.reduce((acc, key) => ({ ...acc, [key]: (process.env || {})[key] }), {}),
              ...(window.__ENV__ || {})
            }
          : filtered(process.env as Env)
      )
    };
  },
  get: (key: Key, defaultValue?: string): string => env.environment()[key] || `${defaultValue}`
};
