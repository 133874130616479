import React from 'react';
import DeleteIcon from '@moda/icons/delete-24';
import { Clickable, Input, Text, Stack } from '@moda/om';

import './Carton.scss';
import { SingleCarton } from '../../../types/data';

interface Props {
  mass: string;
  dimension: string;
  maxWeight: number;
  unitOption: string;
  cartonCount: number;
  cartonIndex: number;
  isDeleteDisabled?: boolean;
  cartonList: SingleCarton[];
  handleRemoveSpecificRow: () => void;
  handleChangeDimension: (index: number, type: string, value: string) => void;
}

export const Carton: React.FC<Props> = ({
  mass,
  maxWeight,
  dimension,
  cartonList,
  unitOption,
  cartonIndex,
  cartonCount,
  isDeleteDisabled,
  handleChangeDimension,
  handleRemoveSpecificRow
}) => {
  return (
    <tr>
      <td>
        <Text treatment="bold1">Carton {cartonCount}</Text>
      </td>
      <td>
        <Stack space={2} direction="horizontal">
          <Input
            id="weight"
            className="Carton__weight"
            error={cartonList[cartonIndex]?.error}
            type="number"
            placeholder={unitOption}
            required
            name="weight"
            onChange={value => {
              handleChangeDimension(cartonIndex, 'weight', value);
            }}
            defaultValue={cartonList[cartonIndex]?.weight}
            max={maxWeight}
            min={0}
          />
          <div className="Carton__mass">{mass}</div>
        </Stack>
      </td>
      <td>
        <Input
          id="length"
          type="number"
          required
          min={0}
          onChange={value => {
            handleChangeDimension(cartonIndex, 'length', value);
          }}
          defaultValue={cartonList[cartonIndex]?.length}
          name="length"
        />
        <div className="Carton__dimension">{dimension}</div>
      </td>
      <td>
        <Input
          id="width"
          type="number"
          required
          min={0}
          onChange={value => {
            handleChangeDimension(cartonIndex, 'width', value);
          }}
          defaultValue={cartonList[cartonIndex]?.width}
          name="width"
        />
        <div className="Carton__dimension">{dimension}</div>
      </td>
      <td>
        <Input
          id="height"
          type="number"
          required
          min={0}
          onChange={value => {
            handleChangeDimension(cartonIndex, 'height', value);
          }}
          defaultValue={cartonList[cartonIndex]?.height}
          name="height"
        />
        <div className="Carton__dimension">{dimension}</div>
      </td>
      <td>
        <Clickable onClick={handleRemoveSpecificRow} disabled={isDeleteDisabled}>
          <DeleteIcon className="Carton__icon" />
        </Clickable>
      </td>
    </tr>
  );
};
