import { useEffect, useState } from 'react';
import { usePrevious } from '@moda/portal-stanchions';
import { PURCHASE_ORDERS } from '../paths';
import { useGetAuthToken } from '../../hooks/useGetAuthToken';
import { PO } from '../../types/data';
import { reportError } from '../../lib/reportError';

export const useFetchPurchaseOrder = (
  poNumber: string
): { data: PO | undefined; errors: Error | null; loading: boolean } => {
  const [data, setData] = useState<PO>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Error | null>(null);
  const previousMode = usePrevious(poNumber);
  const getAuthToken = useGetAuthToken();
  useEffect(() => {
    try {
      const fetchData = (Auth: string | Error | undefined) => {
        fetch(`${PURCHASE_ORDERS}/${poNumber}`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrer: 'no-referrer',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Auth}`
          }
        })
          .then(response => response.json())
          .then(data => {
            if (data.errors && data.errors[0]) {
              const { errors } = data;
              setLoading(false);
              setErrors(errors);
            }
            setLoading(false);
            setData(data);
          });
      };
      getAuthToken().then(Auth => (previousMode != poNumber ? fetchData(Auth) : null));
    } catch (errors) {
      reportError('FETCH_PURCHASE_ORDER', errors);
      setLoading(false);
      setErrors(errors as Error);
    }
  }, [getAuthToken, poNumber, previousMode]);

  return { data, errors, loading };
};
