import React, { useCallback, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { none } from 'ramda';
import classNames from 'classnames';
import { Button, Stack, Text, Input } from '@moda/om';
import { useForm } from '@moda/portal-stanchions';
import ExitIcon from '@moda/icons/exit-16';
import { Style, Sku, SelectedItemsInPage, SkuList } from '../../types/data';
import { ButtonType } from '../ShipmentFormNavigation';
import { SelectionPanelEmptyState } from './SelectionPanelEmptyState';
import './SelectionPanel.scss';

const MOBILE_WIDTH = 922;
interface Props {
  search: string;
  items: Items[];
  className?: string;
  styles: Style[] | undefined;
  onClickButton: (buttonType: ButtonType) => void;
  selectedItemsInPage: SelectedItemsInPage[];
  setItems: Dispatch<SetStateAction<Items[]>>;
  setSelectedItemsInPage: Dispatch<SetStateAction<SelectedItemsInPage[]>>;
  skuForm: SkuList[];
  setSkuForm: Dispatch<SetStateAction<SkuList[]>>;
}

export interface Items {
  quantity: number;
  sku: string;
}

type FormValues = {
  styleName: string;
  styleNumber: string;
  vendorColor: string;
  imageUrl: string;
};

export const SelectionPanel: React.FC<Props> = ({
  items,
  styles,
  setItems,
  className,
  search = '',
  onClickButton,
  selectedItemsInPage,
  setSelectedItemsInPage,
  skuForm,
  setSkuForm
}) => {
  const [currentStyle, setCurrentStyle] = useState<SkuList>({
    styleNumber: '',
    skuNumber: ''
  });
  const [buttonText, setButtonText] = useState('');
  const [data, setData] = useState(styles);
  const [showButton, setShowButton] = useState(false);

  const fillInputValues = useCallback(() => {
    items?.map(item => {
      const inputElement = document.getElementById(`sku${item.sku}`) as HTMLInputElement;
      if (inputElement) inputElement.value = `${item.quantity}`;
    });
  }, [items]);

  useEffect(() => {
    setData(styles);
    fillInputValues();
  }, [styles, fillInputValues]);

  useEffect(() => {
    if (search.length !== 0) {
      setData(
        styles?.filter(style => style.styleNumber.toLowerCase().includes(search.toLowerCase()))
      );
    } else {
      setData(styles);
    }
  }, [search, styles]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      onClickButton((event.target as HTMLButtonElement).innerText as ButtonType);
    },
    [onClickButton]
  );

  const clearSelectedItemsList = () => {
    resetInputValues(undefined);
    setSelectedItemsInPage([]);
    setSkuForm([]);
    setItems([]);
  };

  const buildInPageItems = useCallback(
    (formValues: FormValues) => {
      const selectedSkuItems = skuForm.filter(
        sku =>
          sku.styleNumber == formValues.styleNumber &&
          Object.keys(formValues).includes(sku.skuNumber)
      );
      const selectedSkuNumbers = selectedSkuItems.map(sku => sku.skuNumber);
      const index = selectedItemsInPage.findIndex(
        item =>
          item.styleNumber == formValues.styleNumber &&
          item.skus?.some(sku => selectedSkuNumbers.includes(sku.skuNumber))
      );
      if (index >= 0) {
        selectedItemsInPage[index] = {
          ...selectedItemsInPage[index],
          skus: selectedSkuItems
        };
        setSelectedItemsInPage(selectedItemsInPage);
      } else {
        const inPageItems = [
          {
            styleName: formValues.styleName,
            styleNumber: formValues.styleNumber,
            vendorColor: formValues.vendorColor,
            imageUrl: formValues.imageUrl,
            skus: selectedSkuItems
          }
        ];
        setSelectedItemsInPage([...selectedItemsInPage, ...inPageItems]);
      }
    },
    [selectedItemsInPage, setSelectedItemsInPage, skuForm]
  );

  const buildGeneralItemsList = (skuListing: SkuList[]) => {
    const items = skuListing.map(item => ({
      sku: item.skuNumber,
      quantity: Number(item.quantity)
    }));
    return items;
  };

  const showSubmitButton = useCallback(
    (styleNumber: string, skus: Sku[]) => {
      const selectedSkus = skus.map(sku => sku.skuNumber);
      return (
        currentStyle.styleNumber === styleNumber &&
        showButton &&
        selectedSkus.includes(currentStyle.skuNumber)
      );
    },
    [currentStyle, showButton]
  );

  const getMaxQtyError = useCallback(
    (skuSize: string, styleNumber: string) => {
      return skuForm.filter(sku => sku.skuSize == skuSize && sku.styleNumber == styleNumber)[0]
        ?.error;
    },
    [skuForm]
  );

  const isSkuAlreadyAdded = useCallback(
    (styleNumber: string, skuNumber: string) => {
      return (
        selectedItemsInPage.filter(
          (item: SelectedItemsInPage) =>
            item.styleNumber === styleNumber &&
            item.skus?.map(sku => sku.skuNumber).includes(skuNumber)
        ).length > 0
      );
    },
    [selectedItemsInPage]
  );

  const setSkuList = useCallback(
    (
      skuNumber: string,
      skuSize: string,
      styleNumber: string,
      quantity: string,
      maxQuantity: number
    ) => {
      setShowButton(false);
      let maxQtyError = '';
      const isExistingSku = (sku: SkuList) =>
        sku.skuNumber == skuNumber && sku.styleNumber === styleNumber;
      if (Number(quantity) > maxQuantity) {
        maxQtyError = `Max ${maxQuantity}`;
      }
      if (Number(quantity) > 0) {
        setCurrentStyle({ styleNumber: styleNumber, skuNumber: skuNumber });
        if (none(isExistingSku, skuForm)) {
          skuForm.push({
            skuNumber: skuNumber,
            styleNumber: styleNumber,
            error: maxQtyError,
            quantity: quantity,
            skuSize: skuSize
          });
          setSkuForm(skuForm);
        } else {
          const index = skuForm.findIndex(el => el.skuNumber === skuNumber);
          skuForm[index] = { ...skuForm[index], quantity: quantity, error: maxQtyError };
          setSkuForm(skuForm);
        }
        if (isSkuAlreadyAdded(styleNumber, skuNumber)) {
          setButtonText('UPDATE');
        } else {
          setButtonText('ADD');
        }
        if (maxQtyError == '') setShowButton(true);
      }
    },
    [skuForm, setSkuForm, isSkuAlreadyAdded]
  );

  const resetInputValues = useCallback(
    (index?: number) => {
      let skuIds: string[] | undefined = [];
      if (index) {
        skuIds = selectedItemsInPage[index].skus?.map(sku => sku.skuNumber);
      } else {
        skuIds = items.map(item => item.sku);
      }
      skuIds?.map((skuNumber: string) => {
        const inputElement = document.getElementById(`sku${skuNumber}`) as HTMLInputElement;
        if (inputElement) inputElement.value = '';
      });
    },
    [items, selectedItemsInPage]
  );

  const modifySkuListItem = useCallback(
    (skuNumbers: string[], styleNumber: string) => {
      setSkuForm(
        skuForm.filter(sku => {
          return !(skuNumbers.includes(sku.skuNumber) && sku.styleNumber === styleNumber);
        })
      );
      setItems(
        items.filter(item => {
          return !skuNumbers.includes(item.sku);
        })
      );
    },
    [skuForm, setSkuForm, items, setItems]
  );

  const removeSelectedItem = useCallback(
    (styleNumber: string, skus: SkuList[]) => {
      const deletedSkuNumbers = skus.map(sku => sku.skuNumber);
      const deletedIndex = selectedItemsInPage.findIndex(
        item =>
          item.styleNumber == styleNumber &&
          item.skus?.some(sku => deletedSkuNumbers.includes(sku.skuNumber))
      );
      resetInputValues(deletedIndex);
      modifySkuListItem(deletedSkuNumbers, styleNumber);
      setSelectedItemsInPage(selectedItemsInPage.filter((_, index) => index !== deletedIndex));
    },
    [selectedItemsInPage, setSelectedItemsInPage, resetInputValues, modifySkuListItem]
  );

  const onSubmit = useCallback(
    (formValues: FormValues) => {
      buildInPageItems(formValues);
      const itemsToAdd = buildGeneralItemsList(skuForm);
      setItems(itemsToAdd);
      setCurrentStyle({ styleNumber: '', skuNumber: '' });
    },
    [skuForm, setItems, buildInPageItems]
  );

  const { handleSubmit } = useForm({ onSubmit });

  return (
    <Stack className={classNames('SelectionPanel', className)} space={0} direction="horizontal">
      <Stack className="SelectionPanel__display" space={0}>
        <Stack className="SelectionPanel__display-title" space={0} direction="horizontal">
          <div className="SelectionPanel__spacer" />
          <Text treatment="h6" className="SelectionPanel__header">
            Style Number
          </Text>
          <Text treatment="h6" className="SelectionPanel__header">
            Style Name
          </Text>
          <Text treatment="h6" className="SelectionPanel__header--color">
            Color
          </Text>
          <Text treatment="h6" className="SelectionPanel__header">
            Enter Quantity
          </Text>
          <div className="SelectionPanel__header" />
        </Stack>
        {data && data.length > 0 ? (
          data.map((style: Style, index) => (
            <Stack className="SelectionPanel__body" space={2} direction="horizontal" key={index}>
              <img
                alt={String(style.styleName)}
                className={classNames(`SelectionPanel__image`, className)}
                height={150}
                width={150}
                src={style.imageUrl || '/item.png'}
              />
              <Text className="SelectionPanel__number">{style.styleNumber}</Text>
              <Text className="SelectionPanel__name">{style.styleName}</Text>
              <Text className="SelectionPanel__color">{style.vendorColor}</Text>
              <form className="SelectionPanel__form" onSubmit={handleSubmit}>
                <Stack
                  className="SelectionPanel__quantity"
                  space={0}
                  alignItems="flex-start"
                  direction={window.innerWidth <= MOBILE_WIDTH ? 'vertical' : 'horizontal'}
                >
                  {style.skus.map((sku: Sku) => (
                    <Stack alignItems="center" space={0} direction="vertical" key={sku.skuNumber}>
                      <Text color={sku.quantityRemaining === 0 ? 'elephant' : 'ink'}>
                        {sku.skuSize}
                      </Text>
                      <Input hidden name="styleName" value={style.styleName} />
                      <Input hidden name="styleNumber" value={style.styleNumber} />
                      <Input hidden name="vendorColor" value={style.vendorColor} />
                      <Input hidden name="imageUrl" value={style.imageUrl || '/item.png'} />
                      <Input
                        error={getMaxQtyError(sku.skuSize, style.styleNumber)}
                        className="SelectionPanel__input"
                        type="number"
                        min={0}
                        max={sku.quantityRemaining}
                        id={`sku${sku.skuNumber}`}
                        name={sku.skuNumber}
                        onChange={value =>
                          setSkuList(
                            sku.skuNumber,
                            sku.skuSize,
                            style.styleNumber,
                            value,
                            sku.quantityRemaining
                          )
                        }
                        disabled={sku.quantityRemaining === 0}
                        placeholder={`Max ${sku.quantityRemaining}`}
                      />
                    </Stack>
                  ))}
                </Stack>
                {showSubmitButton(style.styleNumber, style.skus) && (
                  <Button className="SelectionPanel__button" type="submit" secondary chip>
                    {buttonText}
                  </Button>
                )}
              </form>
            </Stack>
          ))
        ) : (
          <Stack
            space={3}
            justifyContent="center"
            alignItems="center"
            className="SelectionPanel__empty-search"
          >
            <Text treatment="bold1">No results found</Text>
            <Text treatment="body1">It seems we can't find any results based on your search.</Text>
          </Stack>
        )}
      </Stack>

      <Stack className="SelectionPanel__selected-items" space={2}>
        <Stack
          className="SelectionPanel__selected-items-title"
          justifyContent="center"
          space={0}
          direction="horizontal"
        >
          <Text color="ink" treatment="h6">
            Selected Items
          </Text>
        </Stack>
        <Stack space={2} className="SelectionPanel__selected--container">
          {selectedItemsInPage?.length > 0 ? (
            selectedItemsInPage?.map((item, index) => (
              <Stack key={index} className="SelectionPanel__selected--item" space={2}>
                <Stack space={0}>
                  <Stack space={0} direction="horizontal" justifyContent="space-between">
                    <Stack className="SelectionPanel__item-details" space={0} direction="vertical">
                      <Text>{item.styleName}</Text>
                      <Text>{item.styleNumber}</Text>
                    </Stack>

                    <ExitIcon
                      className="SelectionPanel__cancel-button"
                      onClick={() => removeSelectedItem(item.styleNumber, item.skus || [])}
                    />
                  </Stack>
                  <div className="SelectionPanel__selected-item-details">
                    <div>
                      <Stack space={8} direction="horizontal">
                        <Text color="fog">Size</Text>
                        <Text color="fog">QTY</Text>
                      </Stack>
                      <Stack space={0}>
                        {item.skus?.map((sku, index) => (
                          <div key={index}>
                            <Stack space={8} direction="horizontal">
                              <Text className="SelectionPanel__item-size">{sku.skuSize}</Text>
                              <Text className="SelectionPanel__item-quantity">{sku.quantity}</Text>
                            </Stack>
                          </div>
                        ))}
                      </Stack>
                    </div>
                    <div className="SelectionPanel__selected-item-color">
                      <Stack space={0}>
                        <Text color="fog">Color</Text>
                        <Text>{item.vendorColor.toUpperCase()}</Text>
                      </Stack>
                    </div>
                  </div>
                </Stack>
              </Stack>
            ))
          ) : (
            <SelectionPanelEmptyState />
          )}
        </Stack>
        <div className="SelectionPanel__selected--actions">
          <Button
            className="SelectionPanel__selected--button"
            secondary
            chip
            disabled={selectedItemsInPage.length == 0}
            onClick={clearSelectedItemsList}
          >
            Cancel
          </Button>
          <Button
            className="SelectionPanel__selected--button"
            secondary
            chip
            disabled={selectedItemsInPage.length == 0}
            onClick={handleClick}
          >
            Next
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};
