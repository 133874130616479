import { useEffect, useState } from 'react';
import { usePrevious } from '@moda/portal-stanchions';
import { PURCHASE_ORDERS } from '../paths';
import { useGetAuthToken } from '../../hooks/useGetAuthToken';
import { Style } from '../../types/data';
import { reportError } from '../../lib/reportError';

export const useFetchStyles = (
  poNumber: string
): {
  stylesData: Style[] | undefined;
  errors: Error | null;
  loading: boolean;
  auth: string | Error | undefined;
} => {
  const [stylesData, setStylesData] = useState<Style[]>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Error | null>(null);
  const [auth, setAuth] = useState<string | Error | undefined>();
  const previousMode = usePrevious(poNumber);
  const getAuthToken = useGetAuthToken();

  useEffect(() => {
    try {
      const fetchData = (Auth: string | Error | undefined) => {
        setAuth(Auth);
        try {
          fetch(`${PURCHASE_ORDERS}/${poNumber}/styles`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrer: 'no-referrer',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Auth}`
            }
          })
            .then(response => response.json())
            .then(data => {
              if (data.errors && data.errors[0]) {
                const { errors } = data;
                setLoading(false);
                setErrors(errors as Error);
              }
              setLoading(false);
              setStylesData(data);
            });
        } catch (error) {
          reportError('FETCH_STYLES', error);
          setErrors(error as Error);
        }
      };
      getAuthToken().then(Auth => (previousMode != poNumber ? fetchData(Auth) : null));
    } catch (errors) {
      reportError('FETCH_STYLES_AND_AUTH_TOKEN', errors);
      setLoading(false);
      setErrors(errors as Error);
    }
  }, [getAuthToken, poNumber, previousMode]);

  return { stylesData, errors, loading, auth };
};
