import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Input, Label, Select, SelectableOption, Stack, Text } from '@moda/om';
import { CARRIERS } from '../../constants/carriers';
import { ShippingInformationData } from '../../types/data';

import './ShippingInformation.scss';

const CARRIER_OPTIONS: SelectableOption[] = CARRIERS.map(carrier => ({
  label: carrier,
  value: carrier
}));

interface Props {
  isLanded: boolean;
  shippingInformationData: ShippingInformationData | undefined;
  setShippingInformationData: Dispatch<SetStateAction<ShippingInformationData | undefined>>;
}

export const ShippingInformation: React.FC<Props> = ({
  isLanded,
  shippingInformationData,
  setShippingInformationData
}) => {
  const handleCarrierInput = useCallback(
    (value: string) => {
      setShippingInformationData({ ...shippingInformationData, ...{ carrier: value } });
    },
    [setShippingInformationData, shippingInformationData]
  );

  const handleTrackingInput = useCallback(
    (value: string) => {
      setShippingInformationData({ ...shippingInformationData, ...{ trackingNumber: value } });
    },
    [setShippingInformationData, shippingInformationData]
  );

  if (!isLanded) return null;

  return (
    <Stack space={3} alignItems="flex-start" className="ShippingInformation">
      <Text treatment="h4">Shipping Information (Optional)</Text>
      <Stack space={1} className="ShippingInformation__carrier">
        <Label>Shipping carrier</Label>
        <Input>
          <Select
            placeholder="Select a shipping carrirer"
            options={CARRIER_OPTIONS}
            onChange={handleCarrierInput}
            value={shippingInformationData?.carrier}
            dropDirection="up"
          />
        </Input>
      </Stack>

      <Stack space={1} className="ShippingInformation__tracking">
        <Label htmlFor="tracking_number">Tracking number</Label>
        <Input
          id="tracking_number"
          onChange={value => handleTrackingInput(value)}
          value={shippingInformationData?.trackingNumber}
        />
      </Stack>
    </Stack>
  );
};
