import { useEffect } from 'react';
import { deferUntilNewTitle } from '@moda/portal-stanchions';
import { tc } from '../../lib/trackingContext';
import { PO } from '../../types/data';

export const useShippingFormTracking = (order: PO | undefined) => {
  useEffect(() => {
    if (order) {
      deferUntilNewTitle(() => {
        tc.track('Interacted with Shipment Form', {
          addToScopeOnce: {
            order: order
          }
        });
      });
    }
  }, [order]);
};
