import React from 'react';
import { Text, Stack, Button, ModalOverlay, LoadingBalls, Divider, ControlLink } from '@moda/om';
import SuccessIcon from '@moda/icons/success-48';
import HandshakeIcon from '@moda/icons/handshake-48';
import { hrefFor } from '../../../routers';
import { Mode } from '../../../hooks/usePurchaseOrder';
import { CONFIG } from '../../../config';
import './PurchaseOrderConfirmationModal.scss';

interface Props {
  mode: Mode;
  error: string | null;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PurchaseOrderConfirmationModal: React.FC<Props> = ({
  mode,
  error,
  loading,
  onClose,
  onConfirm
}) => {
  return (
    <ModalOverlay className="PurchaseOrderConfirmationModal" onClose={onClose}>
      {mode === Mode.Confirmed && (
        <Stack
          className="PurchaseOrderConfirmationModal__success-modal-content"
          space={4}
          alignItems="center"
        >
          <SuccessIcon className="PurchaseOrderConfirmationModal__success-icon" />
          <Stack space={6}>
            <Text treatment="h6" family="serif">
              You have successfully confirmed this PO.
            </Text>
          </Stack>
          <Divider />
          <Button
            secondary
            className="PurchaseOrderConfirmationModal__confirm-button"
            to={hrefFor.OrdersPage()}
          >
            Done
          </Button>
        </Stack>
      )}
      {mode === Mode.Confirming && (
        <Stack
          className="PurchaseOrderConfirmationModal__confirm-modal-content"
          space={4}
          alignItems="center"
        >
          <HandshakeIcon className="PurchaseOrderConfirmationModal__handshake-icon" />
          <Stack space={6}>
            <Text treatment="h6" family="serif">
              By confirming this PO, you agree to be bound by Moda Operandi’s{' '}
              <ControlLink
                href={`${CONFIG.S3}/Moda-Operandi-Vendor+Operating-Guidelines-2020.pdf`}
                rel="noopener noreferrer"
                target="_blank"
                underlined
              >
                <Text treatment="h6" family="serif">
                  Operating Guidelines
                </Text>
              </ControlLink>{' '}
              and{' '}
              <ControlLink
                href={`${CONFIG.S3}/Moda-Operandi-Vendor-Terms-Conditions-2020.pdf`}
                rel="noopener noreferrer"
                target="_blank"
                underlined
              >
                <Text treatment="h6" family="serif">
                  Terms &amp; Conditions
                </Text>
              </ControlLink>
              .
            </Text>
            <Text treatment="h6" family="serif">
              Please note, once this order is confirmed no additional changes can be made.
            </Text>
          </Stack>
          <Divider />
          <Stack space={6} direction="horizontal" alignItems="center" justifyContent="center">
            <ControlLink
              className="PurchaseOrderConfirmationModal__cancel-button"
              onClick={onClose}
              underlined={false}
            >
              Cancel
            </ControlLink>
            <Button
              secondary
              className="PurchaseOrderConfirmationModal__confirm-button"
              onClick={onConfirm}
            >
              Confirm PO
            </Button>
            {error && (
              <Text treatment="body2" color="code-red">
                {error}
              </Text>
            )}
          </Stack>
          {loading && (
            <Stack alignItems="center" space={0}>
              <LoadingBalls />
            </Stack>
          )}
        </Stack>
      )}
    </ModalOverlay>
  );
};
