import { useEffect, useState } from 'react';
import { usePrevious } from '@moda/portal-stanchions';
import { PURCHASE_ORDERS } from '../paths';
import { useGetAuthToken } from '../../hooks/useGetAuthToken';
import { POShipmentData } from '../../types/data';

export const usePurchaseOrderPDF = ({ poNumber }: { poNumber: string }) => {
  const [data, setData] = useState<POShipmentData | Blob>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Error | null>(null);
  const previousMode = usePrevious(poNumber);
  const getAuthToken = useGetAuthToken();

  useEffect(() => {
    try {
      const fetchData = (Auth: string | Error | undefined) => {
        fetch(`${PURCHASE_ORDERS}/${poNumber}.pdf`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrer: 'no-referrer',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${Auth}`
          }
        })
          .then(response => response.blob())
          .then(data => {
            setLoading(false);
            setData(data);
          });
      };
      getAuthToken().then(Auth => (previousMode != poNumber ? fetchData(Auth) : null));
    } catch (errors) {
      setLoading(false);
      setErrors(errors as Error);
    }
  }, [getAuthToken, poNumber, previousMode]);

  return { data, errors, loading };
};
