import React from 'react';
import { Stack, Divider, Text, ControlLink, Textarea, Label, Button, Expandable } from '@moda/om';
import PrevIcon from '@moda/icons/prev-16';
import { Item } from '../../../../types/data';
import { Mode as PurchaseOrderReturnPanelMode } from '../PurchaseOrderReturnPanel';
import './PurchaseOrderReturnPanelPOChange.scss';

interface Props {
  heading: string;
  formData: Item[];
  onCloseForm: () => void;
  mode: PurchaseOrderReturnPanelMode;
  handleIndividualStylesChange: (name: string, value: string) => void;
}

export const PurchaseOrderReturnPanelPOIndividualChange: React.FC<Props> = ({
  mode,
  heading,
  formData,
  onCloseForm,
  handleIndividualStylesChange
}) => {
  return (
    <div className="PurchaseOrderReturnPanelPOChange">
      <div>
        <Text className="PurchaseOrderReturnPanelPOChange__heading" treatment="h5" family="serif">
          Enter Change(s) {heading}
        </Text>
        <div className="PurchaseOrderReturnPanelPOChange__back-link-wrapper">
          <ControlLink
            className="PurchaseOrderReturnPanelPOChange__back-link"
            onClick={onCloseForm}
            rel="noopener noreferrer"
            target="_blank"
            underlined={false}
          >
            <PrevIcon />
            <Text color="cornflower-blue" treatment="h6" family="serif">
              Back
            </Text>
          </ControlLink>{' '}
        </div>
        <Divider />
        <div className="PurchaseOrderReturnPanelPOChange">
          <div className="PurchaseOrderReturnPanelPOChange__fields">
            {mode === PurchaseOrderReturnPanelMode.EditPoStyle && (
              <>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          {' '}
                          Style Number Change
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="style"
                        placeholder="Enter correct style number for image shown"
                        rows={3}
                        defaultValue={
                          formData.find(poStyle => poStyle.changeType == 'Style Number Change')
                            ?.request || ''
                        }
                        onChange={value =>
                          handleIndividualStylesChange('Style Number Change', value)
                        }
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Cancellation
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="cancellation"
                        placeholder="Enter the reason for cancellation"
                        rows={3}
                        defaultValue={
                          formData.find(poStyle => poStyle.changeType == 'Cancellation')?.request ||
                          ''
                        }
                        onChange={value => handleIndividualStylesChange('Cancellation', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Price Change
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="price"
                        placeholder="Enter the new unit price (e.g., WS Unit Price: $100)"
                        rows={3}
                        defaultValue={
                          formData.find(poStyle => poStyle.changeType == 'Price Change')?.request ||
                          ''
                        }
                        onChange={value => handleIndividualStylesChange('Price Change', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Size Change
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="size"
                        placeholder="Enter new size scale/range"
                        rows={3}
                        defaultValue={
                          formData.find(poStyle => poStyle.changeType == 'Size Change')?.request ||
                          ''
                        }
                        onChange={value => handleIndividualStylesChange('Size Change', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          Color/Material Change
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="color"
                        placeholder="Enter new color and/or material"
                        rows={3}
                        defaultValue={
                          formData.find(poStyle => poStyle.changeType == 'Color/Material Change')
                            ?.request || ''
                        }
                        onChange={value =>
                          handleIndividualStylesChange('Color/Material Change', value)
                        }
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">
                          QTY Change
                        </Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="qty"
                        placeholder="Enter new quantity by size (e.g., US 10 QTY 2)"
                        rows={3}
                        defaultValue={
                          formData.find(poStyle => poStyle.changeType == 'QTY Change')?.request ||
                          ''
                        }
                        onChange={value => handleIndividualStylesChange('QTY Change', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
                <div className="PurchaseOrderReturnPanelPOChange__section">
                  <Stack space={1}>
                    <Expandable
                      icon="chevron"
                      name={
                        <Label className="PurchaseOrderReturnPanelPOChange__label">Other</Label>
                      }
                      expanded
                    >
                      <Textarea
                        name="other"
                        placeholder="Enter any other changes that apply to all styles"
                        rows={3}
                        defaultValue={
                          formData.find(poStyle => poStyle.changeType == 'Other')?.request || ''
                        }
                        onChange={value => handleIndividualStylesChange('Other', value)}
                      />
                    </Expandable>
                  </Stack>
                </div>
              </>
            )}
          </div>
          <div className="PurchaseOrderReturnPanelPOChange__submit-section">
            <Divider />
            <Stack space={4} className="PurchaseOrderReturnPanelPOChange__submit">
              <Button
                secondary
                className="PurchaseOrderReturnPanelPOChange__submit-button"
                disabled={false}
                onClick={onCloseForm}
              >
                Done
              </Button>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};
