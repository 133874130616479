import React from 'react';
import { Switch } from 'react-router-dom';
import { PageLayout } from '../components/PageLayout';
import { PAGE_ROUTES } from './PageRoutes';

export const Router = () => (
  <PageLayout>
    <Switch>{PAGE_ROUTES}</Switch>
  </PageLayout>
);
