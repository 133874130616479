import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Divider, Button } from '@moda/om';
import { useAuth0 } from '@auth0/auth0-react';
import { PurchaseOrder } from '../../components/PurchaseOrder';
import { PurchaseOrderConfirmationModal } from '../../components/PurchaseOrder/PurchaseOrderConfirmationModal';
import { Mode } from '../../hooks/usePurchaseOrder';
import { OrderNavBreadcrumbs } from '../../components/OrderNavBreadcrumbs';
import { useFetchPurchaseOrder } from '../../api/purchaseOrders';
import { PURCHASE_ORDERS } from '../../api/paths';
import { hrefFor } from '../../routers';
import { tc } from '../../lib/trackingContext';
import './OrderDetailPage.scss';

export const OrderDetailPage: React.FC = () => {
  const { poNumber } = useParams<{ poNumber: string }>();
  const [authToken, setAuthToken] = useState<string>();
  const { getAccessTokenSilently } = useAuth0();
  const [mode, setMode] = useState(Mode.Resting);
  const { data: poData } = useFetchPurchaseOrder(poNumber);
  const [confirmPOloading, setConfirmPOloading] = useState(false);
  const [confirmPOerror, setConfirmPOerror] = useState(null);

  const trackConfirmPoSubmitted = useCallback(() => {
    tc.track('Confirm PO Submitted', {
      addToScopeOnce: {
        order: poData
      }
    });
  }, [poData]);

  useEffect(() => {
    if (poData) {
      tc.track('Purchase Order Viewed', {
        addToScopeOnce: {
          order: poData
        }
      });
    }
  }, [poData]);

  const onConfirmPO = async () => {
    if (!authToken) return;
    setConfirmPOloading(true);
    await fetch(`${PURCHASE_ORDERS}/${poNumber}/confirm`, {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'no-referrer',
      headers: {
        'Content-Type': 'application/pdf',
        Authorization: `Bearer ${authToken}`
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors && data.errors[0]) {
          const { errors } = data;
          setConfirmPOloading(false);
          setConfirmPOerror(errors);
        }
        setConfirmPOloading(false);
        setMode(Mode.Confirmed);
        trackConfirmPoSubmitted();
      });
  };

  useEffect(() => {
    getAccessTokenSilently().then(Auth => setAuthToken(Auth));
  }, [getAccessTokenSilently, setAuthToken]);

  const onCloseReturnPanel = useCallback(() => {
    setMode(Mode.Resting);
  }, [setMode]);

  const showOrderActions =
    poData && !['Confirmed', 'Fully_Received', 'Receiving', 'Billed'].includes(poData.status);

  const showReturnToPoButton = poData && poData.status === 'Confirmed';

  return (
    <div className="OrderDetailPage">
      <Stack
        className="OrderDetailPage__nav"
        space={0}
        direction="horizontal"
        justifyContent="space-between"
        alignItems="center"
      >
        <OrderNavBreadcrumbs />
        {showOrderActions && (
          <Stack
            direction="horizontal"
            space={2}
            justifyContent="space-around"
            className="OrderDetailPage__order-actions"
          >
            <Button
              className="OrderDetailPage__button-request-change"
              aria-label="Request Change"
              onClick={() => setMode(Mode.Requesting)}
              secondary
              disabled={mode == Mode.Requesting || poData?.changeRequested === true}
            >
              REQUEST CHANGE
            </Button>
            <Button
              className="OrderDetailPage__button-confirm-po"
              aria-label="Confirm PO"
              onClick={() => setMode(Mode.Confirming)}
              secondary
              disabled={
                mode == Mode.Requesting ||
                (poData?.changeRequested === true && poData?.changeAccepted === false)
              }
            >
              CONFIRM PO
            </Button>
          </Stack>
        )}
        {showReturnToPoButton && (
          <Button
            className="OrderDetailPage__button-return-po"
            aria-label="Return To Po"
            to={hrefFor.OrdersPage()}
            secondary
          >
            Return to POs
          </Button>
        )}
      </Stack>
      <Divider />
      <Stack space={0}>
        {(mode === Mode.Confirming || mode === Mode.Confirmed) && (
          <PurchaseOrderConfirmationModal
            mode={mode}
            onClose={() => setMode(Mode.Resting)}
            onConfirm={onConfirmPO}
            loading={confirmPOloading}
            error={confirmPOerror}
          />
        )}
      </Stack>
      <PurchaseOrder
        poData={poData}
        mode={mode}
        poNumber={poNumber}
        onCloseReturnPanel={onCloseReturnPanel}
      />
    </div>
  );
};
