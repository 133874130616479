import React from 'react';
import { Stack } from '@moda/om';
import { Mode } from '../../hooks/usePurchaseOrder';
import { useFetchPurchaseOrder } from '../../api/purchaseOrders';
import { PO } from '../../types/data';
import PurchaseOrderReturnPanel from './PurchaseOrderReturnPanel';
import PurchaseOrderDetailPanel from './PurchaseOrderDetailPanel';
import './PurchaseOrder.scss';

interface Props {
  mode: Mode;
  poNumber: string;
  poData: PO | undefined;
  onCloseReturnPanel: () => void;
}

export const PurchaseOrder: React.FC<Props> = ({ mode, poNumber, onCloseReturnPanel }) => {
  const { data: poData } = useFetchPurchaseOrder(poNumber);
  const disableEditPurchaseOrder =
    poData?.changeRequested === true && poData?.changeAccepted === false;
  return (
    <div className="PurchaseOrder__main">
      <Stack
        space={0}
        justifyContent="center"
        className="PurchaseOrder__box"
        direction="horizontal"
      >
        <PurchaseOrderDetailPanel poNumber={poNumber} />

        {(mode == Mode.Requesting || disableEditPurchaseOrder) && (
          <PurchaseOrderReturnPanel
            disableEditPurchaseOrder={disableEditPurchaseOrder}
            poData={poData}
            onCloseReturnPanel={onCloseReturnPanel}
            poNumber={poNumber}
          />
        )}
      </Stack>
    </div>
  );
};
