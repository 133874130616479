import React from 'react';
import { Text, RadioButton } from '@moda/om';
import classNames from 'classnames';

import './Step.scss';

interface Props {
  title: string;
  checked?: boolean;
}

export const Step: React.FC<Props> = ({ title, checked }) => (
  <div
    className={classNames('Step', {
      'Step--checked': checked
    })}
  >
    <RadioButton checked={checked} readOnly aria-label={`${title} step`} />
    <Text className="Step__title">{title}</Text>
  </div>
);
