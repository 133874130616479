import React, { useCallback, useState, SetStateAction, Dispatch, useEffect } from 'react';
import { Clickable, RadioButton, Stack, Text } from '@moda/om';
import uuid from 'uuid-random';
import { CartonGroup, Cartons, SingleCarton, Unit } from '../../types/data';
import { Carton } from './Carton';

import './PackageDetails.scss';

enum UnitMode {
  US,
  Metric
}

const MAX_WEIGHT_US = 45;
const MAX_WEIGHT_METRIC = 20;

export const validations = {};

interface Props {
  cartons: Cartons[];
  setCartons: Dispatch<SetStateAction<Cartons[]>>;
  setCartonList: Dispatch<SetStateAction<SingleCarton[]>>;
  setPackagingList: Dispatch<SetStateAction<CartonGroup>>;
  cartonList: SingleCarton[];
  packagingList: CartonGroup;
}

export const PackageDetails: React.FC<Props> = ({
  setCartons,
  setPackagingList,
  setCartonList,
  cartonList,
  packagingList
}) => {
  const [mode, setMode] = useState(packagingList.unit.mass == 'lb' ? UnitMode.US : UnitMode.Metric);
  const [unitOptions, setUnitOptions] = useState<Unit>(packagingList.unit);
  const [array, setArray] = useState<React.HTMLAttributes<HTMLTableRowElement>[]>([{ id: uuid() }]);
  const addNewRow = useCallback(() => {
    setCartons([
      {
        parcel: {
          massUnit: '',
          weight: ''
        }
      }
    ]);
    setArray([...array, { id: uuid() }]);
  }, [array, setCartons]);

  const handleRemoveSpecificRow = useCallback(
    (row: React.HTMLAttributes<HTMLTableRowElement>) => {
      const rows = [...array];
      rows.splice(rows.indexOf(row), 1);
      setArray(rows);
    },
    [array]
  );

  const getMaxWeight = useCallback(() => {
    const endingString = unitOptions.mass === 'lb' ? 's' : '';
    let maxWeight = MAX_WEIGHT_US;
    if (mode === UnitMode.Metric) {
      maxWeight = MAX_WEIGHT_METRIC;
    }
    const maxWeightWithUnit = `${maxWeight}${unitOptions.mass}${endingString}`;
    return { maxWeight, maxWeightWithUnit };
  }, [mode, unitOptions.mass]);

  useEffect(() => {
    setPackagingList(attributes => ({ ...attributes, unit: unitOptions }));
    const { maxWeightWithUnit } = getMaxWeight();
    cartonList
      .filter(el => el.error != '')
      .map(el => (el.error = `Package weight shouldn't be greater than ${maxWeightWithUnit}`));
  }, [cartonList, getMaxWeight, mode, setPackagingList, unitOptions]);

  const addCarton = useCallback(
    (type: string, value: string, error: string) => {
      const cartonEmptyHash = { height: '', width: '', weight: '', length: '', error: error };
      const updatedCartonHash = { ...cartonEmptyHash, [type]: value };
      setCartonList([...cartonList, updatedCartonHash]);
    },
    [cartonList, setCartonList]
  );

  const updateCarton = useCallback(
    (index: number, type: string, value: string, error: string) => {
      cartonList[index] = { ...cartonList[index], [type]: value, error: error };
      setCartonList(cartonList);
    },
    [cartonList, setCartonList]
  );

  const getMaxQtyError = useCallback(
    (index: number, type: string, value: string) => {
      if (type != 'weight') return cartonList[index]?.error;
      let error = '';
      const { maxWeight, maxWeightWithUnit } = getMaxWeight();
      if (Number(value) > maxWeight) {
        error = `Package weight shouldn't be greater than ${maxWeightWithUnit}`;
      } else {
        error = '';
      }
      return error;
    },
    [cartonList, getMaxWeight]
  );

  const handleChangeDimension = useCallback(
    (index: number, type: string, value: string) => {
      const maxQtyError = getMaxQtyError(index, type, value);
      if (!cartonList[index]) {
        addCarton(type, value, maxQtyError);
      } else {
        updateCarton(index, type, value, maxQtyError);
      }
      setPackagingList({ unit: unitOptions, cartons: cartonList });
    },
    [getMaxQtyError, cartonList, setPackagingList, unitOptions, addCarton, updateCarton]
  );

  const handleModeChange = useCallback(() => {
    if (mode === UnitMode.US) {
      setMode(UnitMode.Metric);
      setUnitOptions({ mass: 'kg', dimension: 'cm' });
    }

    if (mode === UnitMode.Metric) {
      setMode(UnitMode.US);
      setUnitOptions({ mass: 'lb', dimension: 'in' });
    }
  }, [mode]);

  return (
    <Stack className="PackageDetails" space={4}>
      <Stack space={1} direction="horizontal" justifyContent="space-between" alignItems="center">
        <Text treatment="h4">Package Details</Text>
        <Stack space={4} direction="horizontal">
          <Text treatment="body1">Unit:</Text>
          <RadioButton value="US" checked={mode === UnitMode.US} onChange={handleModeChange}>
            US
          </RadioButton>
          <RadioButton
            value="Metric"
            checked={mode === UnitMode.Metric}
            onChange={handleModeChange}
          >
            Metric
          </RadioButton>
        </Stack>
      </Stack>

      <table className="PackageDetails__table">
        <thead>
          <tr>
            <th />
            <th className="PackageDetails__header">
              <label htmlFor="weight">
                <Text treatment="bold1">Weight</Text>
              </label>
            </th>
            <th className="PackageDetails__header">
              <label htmlFor="length">
                <Text treatment="bold1">Length</Text>
              </label>
            </th>
            <th className="PackageDetails__header">
              <label htmlFor="width">
                <Text treatment="bold1">Width</Text>
              </label>
            </th>
            <th className="PackageDetails__header">
              <label htmlFor="height">
                <Text treatment="bold1">Height</Text>
              </label>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {array &&
            array.map((row, index) => (
              <Carton
                key={row.id}
                unitOption={mode === UnitMode.US ? 'Max 45 lb' : 'Max 20 kg'}
                isDeleteDisabled={array.length === 1 ? true : false}
                cartonCount={index + 1}
                cartonIndex={index}
                cartonList={cartonList}
                dimension={unitOptions.dimension}
                mass={unitOptions.mass}
                handleChangeDimension={handleChangeDimension}
                maxWeight={mode === UnitMode.US ? MAX_WEIGHT_US : MAX_WEIGHT_METRIC}
                handleRemoveSpecificRow={() => handleRemoveSpecificRow(row)}
              />
            ))}
        </tbody>
      </table>
      <Clickable className="PackageDetails__button" onClick={addNewRow}>
        <Text treatment="body1" color="cornflower-blue">
          + Add New Carton
        </Text>
      </Clickable>
    </Stack>
  );
};
