import React, { useCallback } from 'react';
import { Clickable, ControlLink, Stack, Text } from '@moda/om';
import LogoVendorPortalIcon from '@moda/icons/logo-vendor-portal-288-x-31';
import { useAuth0 } from '@auth0/auth0-react';
import { tc } from '../../lib/trackingContext';
import { hrefFor } from '../../routers';

import './TopNavigation.scss';

export const TopNavigation: React.FC = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const hideLogout = window.location.pathname.startsWith('/terms-and-conditions');

  const handleSignedOut = useCallback(() => {
    tc.track('Signed Out', { addToTrackingEvent: { email: user?.email || '' } });
    logout({ logoutParams: { returnTo: `${window.location.origin}/login` } });
  }, [logout, user]);

  const trackBreadCrumbClicked = useCallback(() => {
    tc.track('Breadcrumb Clicked');
  }, []);

  return (
    <Stack
      className="TopNavigation"
      space={0}
      direction="horizontal"
      justifyContent="space-between"
      alignItems="center"
    >
      <Clickable
        className="TopNavigation__link"
        href={hrefFor.OrdersPage()}
        onClick={trackBreadCrumbClicked}
      >
        <LogoVendorPortalIcon />
      </Clickable>

      {user && isAuthenticated && !hideLogout && (
        <Stack space={0} alignItems="flex-end">
          <Text treatment="bold1" color="cornflower-blue">
            {user?.email}
          </Text>
          <ControlLink
            className="TopNavigation__logout"
            underlined={false}
            onClick={() => handleSignedOut()}
          >
            <Text treatment="bold1" color="cornflower-blue">
              Log Out
            </Text>
          </ControlLink>
        </Stack>
      )}
    </Stack>
  );
};
