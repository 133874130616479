import React from 'react';
import { Stack } from '@moda/om';
import { PurchaseOrders } from '../../components/PurchaseOrders';

import './OrdersPage.scss';

const OrdersPage: React.FC = () => {
  return (
    <Stack space={2} direction="horizontal">
      <PurchaseOrders />
    </Stack>
  );
};

export default OrdersPage;
