import React, { useState } from 'react';
import { Label, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import CalendarIcon from '@moda/icons/calendar-20';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './ShippingDateSelector.scss';

interface Props {
  heading?: string;
  label?: string;
  name?: string;
  defaultValue?: string;
  className?: string;
  handleDateSelect?: (date: Date | null, label?: string) => void;
}

export const ShippingDateSelector: React.FC<Props> = ({
  heading,
  label,
  name,
  defaultValue,
  className,
  handleDateSelect
}) => {
  const initialDate = defaultValue && defaultValue.length > 0 ? new Date(defaultValue) : null;
  const [shippingDate, setShippingDate] = useState<Date | null>(initialDate);
  const handleDateChange = (date: Date | null, label: string | undefined) => {
    setShippingDate(date);
    handleDateSelect && handleDateSelect(date, label);
  };
  return (
    <Stack
      space={3}
      alignItems="flex-start"
      className={classNames('ShippingDateSelector', className)}
    >
      <Text treatment="h4">{heading}</Text>
      <div className="ShippingDateSelector__date-picker-wrapper">
        <div className="ShippingDateSelector__header">
          <label htmlFor="shipping_date">
            <Text treatment="bold1">{label}</Text>
          </label>
        </div>
        <Label className="ShippingDateSelector__label">
          <DatePicker
            name={name}
            selected={shippingDate}
            onSelect={date => {
              handleDateSelect && date instanceof Date && handleDateSelect(date, label);
            }}
            onChange={(value: Date | null) => handleDateChange(value, label)}
            minDate={new Date()}
            popperPlacement="bottom"
            placeholderText="MM/DD/YYYY"
          />
          <div className="ShippingDateSelector__icon">
            <CalendarIcon />
          </div>
        </Label>
      </div>
    </Stack>
  );
};
