import React from 'react';
import { Text, Stack, Button, ModalOverlay, LoadingBalls, Divider, ControlLink } from '@moda/om';

import './FulfillAllModal.scss';

export enum FulfillMode {
  Resting,
  FulfillAll,
  Confirming,
  Confirmed
}

interface Props {
  mode: FulfillMode;
  onClose: () => void;
  onConfirm: () => void;
}

export const FulfillAllModal: React.FC<Props> = ({ mode, onClose, onConfirm }) => {
  return (
    <ModalOverlay className="FulfillAllModal" onClose={onClose}>
      {mode === FulfillMode.Confirming && (
        <Stack className="FulfillAllModal__confirm-modal-content" space={4} alignItems="center">
          <Stack space={6}>
            <Text treatment="h5" family="serif">
              Are you sure you want to fulfill all items on this PO?
            </Text>
            <Text treatment="h6" family="serif">
              By selecting "Fulfill All", you agree to ship all styles and the maximum quantities of
              each style listed on this PO.
            </Text>
          </Stack>
          <Divider />
          <Stack space={6} direction="horizontal" alignItems="center" justifyContent="center">
            <ControlLink
              className="FulfillAllModal__cancel-button"
              onClick={onClose}
              underlined={false}
            >
              Cancel
            </ControlLink>
            <Button secondary className="FulfillAllModal__confirm-button" onClick={onConfirm}>
              Fulfill All
            </Button>
          </Stack>
          {false && (
            <Stack alignItems="center" space={0}>
              <LoadingBalls />
            </Stack>
          )}
        </Stack>
      )}
    </ModalOverlay>
  );
};
