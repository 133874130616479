import React, { Dispatch, SetStateAction, useCallback, useState, useEffect } from 'react';
import { Clickable, ControlLink, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@moda/icons/delete-24';
import DocumentIcon from '@moda/icons/document-24';

import './FileUploader.scss';

interface Props {
  isDomesticShipment: boolean;
  uploadData: File[];
  setUploadData: Dispatch<SetStateAction<File[]>>;
  fileSizeMb: number;
  fileSizeError: string;
  setFileSizeMb: Dispatch<SetStateAction<number>>;
  setFileSizeError: Dispatch<SetStateAction<string>>;
}

export const FileUploader: React.FC<Props> = ({
  isDomesticShipment,
  uploadData,
  fileSizeMb,
  fileSizeError,
  setFileSizeMb,
  setFileSizeError,
  setUploadData
}) => {
  const FILE_SIZE_LIMIT_MB = 20;
  const [myFiles, setMyFiles] = useState<File[]>(uploadData);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const bytesToMegaBytes = (bytes: number) => parseFloat((bytes / (1024 * 1024)).toFixed(2));

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
      const uploadedFiles = acceptedFiles.map(file => file);
      setUploadData([...uploadData, ...uploadedFiles]);
    },
    [myFiles, uploadData, setUploadData]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
  });

  const removeFile = (file: File) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    setUploadData(newFiles);
  };

  useEffect(() => {
    if (fileSizeMb > FILE_SIZE_LIMIT_MB) {
      setFileSizeError('Upload size should not exceed 20 MB. Please try again!');
    } else {
      setFileSizeError('');
    }
  }, [fileSizeMb, setFileSizeError]);

  useEffect(() => {
    const totalFileSizeMb = uploadData.reduce(
      (total, file) => total + bytesToMegaBytes(file.size),
      0
    );
    setFileSizeMb(totalFileSizeMb);
  }, [uploadData, setFileSizeMb]);

  const files = myFiles.map((file: File) => (
    <Stack
      space={2}
      key={file.name}
      className="FileUploader__file-info"
      direction="horizontal"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack space={2} direction="horizontal" alignItems="center">
        <DocumentIcon className="FileUploader__icon" />
        {file.name}
      </Stack>
      <Clickable onClick={removeFile(file)}>
        <DeleteIcon className="FileUploader__icon" />
      </Clickable>
    </Stack>
  ));

  if (isDomesticShipment) return null;

  return (
    <Stack space={4} className="FileUploader">
      <Text treatment="h4">Upload Customs Documentation</Text>
      <Stack space={2} className="FileUploader__wrapper">
        <Text treatment="body1">
          <ControlLink
            href="/pdfs/moda-shipping-guide.pdf"
            rel="noopener noreferrer"
            target="_blank"
            underlined
          >
            Click here
          </ControlLink>{' '}
          to view US Customs guidelines
        </Text>

        <Stack space={3}>
          <Text color="code-red">{fileSizeError} </Text>
          <div
            {...getRootProps({
              className: classNames('FileUploader__dropzone', {
                'FileUploader__dropzone--active': isDragActive
              })
            })}
          >
            <input {...getInputProps()} />
            <Text treatment="h6" color="fog">
              Click to select files
            </Text>
          </div>

          {files.length > 0 && (
            <Stack space={1}>
              <Text treatment="body1">Your files:</Text>
              <Stack space={1} className="FileUploader__uploaded-files">
                {files}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
