import React from 'react';
import { generatePath, Redirect, Route, RouteProps } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import OrdersPage from '../pages/OrdersPage/OrdersPage';
import OrderDetailPage from '../pages/OrderDetailPage';
import ShipmentPage from '../pages/ShipmentPage/ShipmentPage';
import PackingListPage from '../pages/PackingListPage';
import { TermsAndConditionsPage } from '../pages/TermsAndConditionsPage/TermsAndConditionsPage';
import { CONFIG } from '../config';

type PageName =
  | 'HomePage'
  | 'LoginPage'
  | 'OrdersPage'
  | 'ShipmentPage'
  | 'OrderDetailPage'
  | 'ShippingAuthPage'
  | 'TermsAndConditionsPage'
  | 'PackingListPage';

export type PageRoute = RouteProps & {
  key: PageName;
  path: string;
};

export const ROUTES: PageRoute[] = [
  {
    key: 'HomePage',
    path: '/',
    component: () => (
      <PrivateRoute>
        <Redirect to={hrefFor.OrdersPage()} />
      </PrivateRoute>
    ),
    exact: true
  },
  {
    key: 'LoginPage',
    path: '/login',
    component: () => (
      <PrivateRoute>
        <Redirect to={hrefFor.OrdersPage()} />
      </PrivateRoute>
    ),
    exact: true
  },
  {
    key: 'OrdersPage',
    path: '/orders',
    component: () => <PrivateRoute component={OrdersPage} />,
    exact: true
  },
  {
    key: 'OrderDetailPage',
    path: '/order/:poNumber',
    component: () => <PrivateRoute component={OrderDetailPage} />,
    exact: true
  },
  {
    key: 'ShipmentPage',
    path: `/order/:poNumber/shipping-auth`,
    component: () => <PrivateRoute component={ShipmentPage} />
  },
  {
    key: 'TermsAndConditionsPage',
    path: `/terms-and-conditions`,
    component: TermsAndConditionsPage
  },
  {
    key: 'PackingListPage',
    path: '/packing-list/:asnsNumber',
    component: () => (
      <PrivateRoute
        component={PackingListPage}
        data={{
          url: `${CONFIG.API}/asns`
        }}
      />
    )
  }
];

export type Params = { [param: string]: string };

export type HrefFor = Record<PageName, (params?: Record<string, string>) => string>;

export const hrefFor: HrefFor = ROUTES.reduce((acc, route) => {
  return { ...acc, [route.key]: (params = {}) => generatePath(route.path, params) };
}, {}) as HrefFor;

export const PAGE_ROUTES = ROUTES.map(({ key, path, component, ...rest }) => (
  <Route key={key} path={path} component={component} {...rest} />
));
