import { useCallback, useState } from 'react';
import {
  POINT_OF_MANUFACTURING_KEYS,
  SHIP_FROM_ADDRESS_KEYS,
  SHIP_TO_ADDRESS_KEYS
} from '../../constants/poStatuses';
import { PURCHASE_ORDERS, SHIPPING_LABEL, SUBMIT_SHIPPING } from '../../api/paths';
import { State } from '../../pages/ShipmentPage/ShipmentPage';
import { SingleCarton, PO, Items } from '../../types/data';
import { tc } from '../../lib/trackingContext';
import { reportError } from '../../lib/reportError';

export const useShipment = (
  state: State,
  items: Items[],
  purchaseOrder: { data: PO | undefined; errors: Error | null; loading: boolean },
  shipmentDate: string,
  poNumber: string,
  auth?: string | Error | undefined
) => {
  const [shipmentloading, setShipmentLoading] = useState(false);
  const [successShipment, setSuccessShipment] = useState(false);
  const [shipmentError, setShipmentError] = useState('');

  const handleTrackShipmentSuccess = useCallback(() => {
    if (purchaseOrder?.data) {
      tc.track('Shipment Submitted', {
        addToScopeOnce: {
          order: purchaseOrder.data
        }
      });
    }
  }, [purchaseOrder]);

  const handleShipmentError = useCallback(() => {
    const errorMessage =
      'There was an error while submitting the shipment request. Please try again';
    if (purchaseOrder?.data) {
      tc.track('Shipment Error Encountered', {
        addToScopeOnce: {
          order: purchaseOrder.data
        },
        addToTrackingEvent: {
          errorMessage: errorMessage
        }
      });
    }
    setShipmentError(errorMessage);
  }, [purchaseOrder]);

  const handleFormSubmit = useCallback(async () => {
    setShipmentLoading(true);
    const shipFromAddress = {
      company: state?.address?.company,
      country: state?.address?.countryShortCode,
      name: state?.address?.firstName + ' ' + state?.address?.lastName,
      street1: state?.address?.addressLine1,
      street2: state?.address?.addressLine2,
      city: state?.address?.city,
      state: state?.address?.state,
      zipCode: state?.address?.postalCode,
      phone: state?.address?.phone
    };
    const shipToAddress = {
      company: purchaseOrder?.data?.shipToAddress.company || '',
      country: purchaseOrder?.data?.shipToAddress.countryCode,
      name:
        purchaseOrder?.data?.shipToAddress.firstName +
        ' ' +
        purchaseOrder?.data?.shipToAddress.lastName,
      street1: purchaseOrder?.data?.shipToAddress.addressLine1,
      street2: purchaseOrder?.data?.shipToAddress.addressLine2,
      city: purchaseOrder?.data?.shipToAddress.city,
      state: purchaseOrder?.data?.shipToAddress.state,
      zipCode: purchaseOrder?.data?.shipToAddress.postalCode,
      phone: purchaseOrder?.data?.shipToAddress.phone || ''
    };

    try {
      const asnResponse = await fetch(`${PURCHASE_ORDERS}/${poNumber}/asn`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrer: 'no-referrer',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth}`
        },
        body: JSON.stringify({
          shipFromAddress,
          estimatedShipDate: shipmentDate,
          items
        })
      });
      const data = await asnResponse.json();

      if (!asnResponse.ok) {
        setShipmentLoading(false);
        handleShipmentError();
        return;
      }

      const mass = state.shippingDetails?.cartonList.unit.mass;
      if (data.asnId) {
        const asnId = data.asnId;
        const shippingResponse = await fetch(`${SUBMIT_SHIPPING}`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          redirect: 'follow',
          referrer: 'no-referrer',
          headers: {
            Authorization: `Bearer ${auth}`
          },
          body: getShipmentPayload(asnId, poNumber, purchaseOrder, state)
        });

        const submitResponse = await shippingResponse.json();
        if (!shippingResponse.ok) {
          setShipmentLoading(false);
          handleShipmentError();
          return;
        }
        if (submitResponse) {
          const sendData = {
            fromAddress: shipFromAddress,
            toAddress: shipToAddress,
            cartons: state.shippingDetails?.cartonList.cartons.map(
              (carton: { weight: string }) => ({
                parcel: { massUnit: mass, weight: Number(carton.weight) }
              })
            )
          };
          const shippingLabelResponse = await fetch(`${SHIPPING_LABEL}?asnNumber=${asnId}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            redirect: 'follow',
            referrer: 'no-referrer',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth}`
            },
            body: JSON.stringify(sendData)
          });
          if (shippingLabelResponse.ok) {
            setShipmentLoading(false);
            setSuccessShipment(true);
            handleTrackShipmentSuccess();
          } else {
            setShipmentLoading(false);
            handleShipmentError();
          }
        }
      }
    } catch (error) {
      reportError('SUBMIT_SHIPMENT', error);
      setShipmentLoading(false);
      handleShipmentError();
    }
  }, [
    auth,
    items,
    purchaseOrder,
    poNumber,
    shipmentDate,
    state,
    handleTrackShipmentSuccess,
    handleShipmentError
  ]);
  return { successShipment, shipmentError, shipmentloading, handleFormSubmit };
};

const getShipmentPayload = (
  asnId: number,
  poNumber: string,
  purchaseOrder: { data: PO | undefined; errors: Error | null; loading: boolean },
  state: State
) => {
  const formData = new FormData();
  const toAddress = purchaseOrder?.data?.shipToAddress;

  if (state.address) {
    Object.entries(state.address).forEach(([key, value]) => {
      const addressValue: string = (value as string) || '';
      if (SHIP_FROM_ADDRESS_KEYS[key as keyof typeof SHIP_FROM_ADDRESS_KEYS]) {
        formData.append(
          SHIP_FROM_ADDRESS_KEYS[key as keyof typeof SHIP_FROM_ADDRESS_KEYS],
          addressValue.toString()
        );
      }
    });
  }

  if (toAddress) {
    Object.entries(toAddress).forEach(([key, value]) => {
      const addressValue: string = (value as string) || '';
      if (POINT_OF_MANUFACTURING_KEYS[key as keyof typeof POINT_OF_MANUFACTURING_KEYS]) {
        formData.append(
          POINT_OF_MANUFACTURING_KEYS[key as keyof typeof POINT_OF_MANUFACTURING_KEYS],
          addressValue.toString()
        );
      }
    });
  }

  if (toAddress) {
    Object.entries(toAddress).forEach(([key, value]) => {
      const addressValue: string = (value as string) || '';
      if (SHIP_TO_ADDRESS_KEYS[key as keyof typeof SHIP_TO_ADDRESS_KEYS]) {
        formData.append(
          SHIP_TO_ADDRESS_KEYS[key as keyof typeof SHIP_TO_ADDRESS_KEYS],
          addressValue.toString()
        );
      }
    });
  }

  if (state.shippingDetails) {
    let unit = state.shippingDetails.cartonList.unit;
    if (
      state.shippingDetails.shippingInformation?.carrier &&
      state.shippingDetails.shippingInformation.carrier.includes('FedEx')
    ) {
      unit = { mass: 'lb', dimension: 'in' };
    }
    const dimensionNotation = unit.dimension === 'in' ? '"' : unit.dimension;
    formData.append(
      'estShippingDate',
      state.shippingDetails.shipmentDate &&
        new Date(state.shippingDetails.shipmentDate).toLocaleDateString('en-US', {
          timeZone: 'UTC',
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
    );
    formData.append('shippingNotes', '');

    state.shippingDetails.uploadData?.forEach((file: File) => {
      if (file) formData.append('attachments', file);
    });

    state.shippingDetails.cartonList.cartons.forEach((carton: SingleCarton, index: number) => {
      formData.append(
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        `carton${(index + 1).toString().padStart(3, '0')}`,
        `Carton ${index + 1} - ${carton.weight} ${unit.mass}${
          Number(carton.weight) > 1 && unit.mass === 'lb' ? 's' : ''
        }, ${carton.length}${
          dimensionNotation != '"' ? ` ${dimensionNotation}` : dimensionNotation
        } L, ${carton.width}${
          dimensionNotation != '"' ? ` ${dimensionNotation}` : dimensionNotation
        } W, ${carton.height}${
          dimensionNotation != '"' ? ` ${dimensionNotation}` : dimensionNotation
        } H`
      );
    });
  }

  const carrier = state.shippingDetails?.shippingInformation?.carrier || '';
  const trackingNumber = state.shippingDetails?.shippingInformation?.trackingNumber || '';

  //these fields needs to be added in shipping details form
  formData.append('carrier', carrier);
  formData.append('trackingNumber', trackingNumber);

  formData.append('poNumber', poNumber);
  formData.append('asnId', asnId ? asnId.toString() : '');
  return formData;
};
