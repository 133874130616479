import React from 'react';
import { Text, Stack, Button, ModalOverlay, Divider, ControlLink } from '@moda/om';
import SuccessIcon from '@moda/icons/success-48';
import { hrefFor } from '../../../../routers';
import { Mode as PurchaseOrderReturnPanelMode } from '../PurchaseOrderReturnPanel';
import './PurchaseOrderReturnPanelModal.scss';

interface Props {
  error?: string;
  onClose: () => void;
  onCloseReturnPanel: () => void;
  mode: PurchaseOrderReturnPanelMode;
}

export const PurchaseOrderReturnPanelModal: React.FC<Props> = ({
  mode,
  error,
  onClose,
  onCloseReturnPanel
}) => {
  return (
    <ModalOverlay className="PurchaseOrderReturnPanelModal" onClose={onClose}>
      {mode === PurchaseOrderReturnPanelMode.Saved && (
        <Stack
          className="PurchaseOrderReturnPanelModal__confirm-modal-content"
          space={4}
          alignItems="center"
        >
          <SuccessIcon className="PurchaseOrderReturnPanelModal__success-icon" />
          <Stack space={6}>
            <Text treatment="h6" family="serif">
              Your request(s) have been successfully submitted. We will review the changes, update
              the PO and resend to you to review and approve.
            </Text>
          </Stack>
          <Button
            secondary
            className="PurchaseOrderReturnPanelModal__confirm-button"
            to={hrefFor.OrdersPage()}
          >
            OK
          </Button>
        </Stack>
      )}
      {mode === PurchaseOrderReturnPanelMode.Cancelled && (
        <Stack
          className="PurchaseOrderReturnPanelModal__cancel-modal-content"
          space={4}
          alignItems="center"
        >
          <Stack space={6}>
            <Text
              className="PurchaseOrderReturnPanelModal__cancel-heading"
              treatment="h5"
              family="serif"
            >
              Are you sure you want to cancel all changes?
            </Text>
          </Stack>
          <Stack space={6} direction="horizontal" alignItems="center" justifyContent="center">
            <Text treatment="h6" family="serif">
              Selecting "Cancel All Changes" will delete all the changes you've requested for this
              Purchase Order.
            </Text>
          </Stack>
          <Divider />
          <Button
            secondary
            className="PurchaseOrderReturnPanelModal__cancel-button"
            onClick={onCloseReturnPanel}
          >
            Cancel All Changes
          </Button>
        </Stack>
      )}
      {mode === PurchaseOrderReturnPanelMode.Error && (
        <Stack
          className="PurchaseOrderReturnPanelModal__cancel-modal-content"
          space={4}
          alignItems="center"
        >
          <Stack space={6}>
            <Text
              className="PurchaseOrderReturnPanelModal__cancel-heading"
              treatment="h5"
              family="serif"
            >
              There was an issue processing your change.
              <br />
              {error}
            </Text>
          </Stack>
          <Text>
            <ControlLink className="ErrorPage__action" href="mailto:retail.ops@modaoperandi.com">
              Contact us
            </ControlLink>{' '}
            about any issues.
          </Text>
          <Divider />
          <Button
            secondary
            className="PurchaseOrderReturnPanelModal__cancel-button"
            onClick={onCloseReturnPanel}
          >
            Return
          </Button>
        </Stack>
      )}
    </ModalOverlay>
  );
};
