import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PrivateRoute = ({ component, ...args }: React.PropsWithChildren<any>) => (
  <Route
    render={props => {
      const Component = withAuthenticationRequired(component, {});
      return <Component {...props} />;
    }}
    {...args}
  />
);
