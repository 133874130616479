export const CARRIERS = [
  'AmazonMws',
  'APC',
  'Aramex',
  'ArrowXL',
  'Asendia',
  'Australia Post',
  'AxlehireV3',
  'BorderGuru',
  'Cainiao',
  'Canada Post',
  'Canpar',
  'CDL Last Mile Solutions',
  'Chronopost',
  'Colis Privé',
  'Colissimo',
  'CouriersPlease',
  'Dai Post',
  'Deliv',
  'Deutsche Post UK',
  'Deutsche Post',
  'DHL eCommerce Asia',
  'DHL eCommerce International',
  'DHL eCommerce',
  'DHL Express',
  'DHL Freight',
  'DHL Germany',
  'Dicom',
  'Direct Link',
  'Doorman',
  'DPD UK',
  'DPD',
  'EMS',
  'Estafeta',
  'Estes',
  'Fastway',
  'FedEx Mailview',
  'FedEx SameDay City',
  'FedEx UK',
  'FedEx',
  'FirstMile',
  'Globegistics',
  'GSO',
  'Hermes',
  'Hong Kong Post',
  'Interlink Express',
  'Janco Freight',
  'JP Post',
  'Kuroneko Yamato',
  'La Poste',
  'LaserShipV2',
  'Latvijas Pasts',
  'Liefery',
  'Loomis Express',
  'LSO',
  'Network4',
  'Newgistics',
  'Norco',
  'OmniParcel',
  'OnTrac DirectPost',
  'OnTrac',
  'Orange DS',
  'Osm Worldwide',
  'Parcel',
  'Parcelforce',
  'Passport',
  'Pilot',
  'Posten',
  'PostNL',
  'PostNord',
  'Purolator',
  'Royal Mail',
  'RR Donnelley',
  'Seko',
  'Singapore Post',
  'Spee-Dee',
  'SprintShip',
  'StarTrack',
  'TForce',
  'Toll',
  'UDS',
  'Ukrposhta',
  'UPS i-parcel',
  'UPS',
  'USPS',
  'Veho',
  'Yanwen',
  'Yodel'
];
