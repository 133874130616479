import React from 'react';
import { Stack, Text } from '@moda/om';

import './SelectionPanelEmptyState.scss';

export const SelectionPanelEmptyState = () => {
  return (
    <Stack className="SelectionPanelEmptyState" space={4} justifyContent="center">
      <Stack space={0}>
        <Text color="cement" treatment="body1">
          You have 0 selected items to ship.
        </Text>
        <Text color="cement" treatment="body1">
          Please add items from the left panel.
        </Text>
      </Stack>
      <img className="SelectionPanelEmptyState__img" alt="empty" src="/empty-box.svg" />
    </Stack>
  );
};
