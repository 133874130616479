import React from 'react';
import { ControlLink, Stack, Text } from '@moda/om';

import './ErrorPage.scss';

export const ErrorPage: React.FC = () => {
  return (
    <tbody>
      <tr className="ErrorPage">
        <td colSpan={4}>
          <Stack space={2} className="ErrorPage__content">
            <Text treatment="h3" className="ErrorPage__message">
              No Purchase Orders Found
            </Text>
            <div className="ErrorPage__action-link">
              <Text>
                <ControlLink aria-label="Try again" href="." className="ErrorPage__action">
                  Try again
                </ControlLink>{' '}
                or{' '}
                <ControlLink
                  className="ErrorPage__action"
                  href="mailto:retail.ops@modaoperandi.com"
                >
                  contact us
                </ControlLink>{' '}
                about the issue.
              </Text>
            </div>
          </Stack>
        </td>
        <td colSpan={4}>
          <img className="ErrorPage__image" alt="error" src="/order-table-error.svg" />
        </td>
      </tr>
    </tbody>
  );
};
