import React, { useState, useCallback, useEffect } from 'react';
import './PurchaseOrderReturnPanel.scss';
import { Stack, Divider, Text, Clickable, Button, ControlLink, Loading } from '@moda/om';
import { useAuth0 } from '@auth0/auth0-react';
import { PORequest, Item, PO, PurchaseOrderChangeRequest } from '../../../types/data';
import { PURCHASE_ORDERS } from '../../../api/paths';
import { tc } from '../../../lib/trackingContext';
import PurchaseOrderReturnPanelModal from './PurchaseOrderReturnPanelModal';
import {
  PurchaseOrderReturnPanelPOInfoChange,
  PurchaseOrderReturnPanelPOIndividualChange
} from './PurchaseOrderReturnPanelPOChange';

export enum Mode {
  Default,
  EditPo,
  EditPoStyle,
  Saved,
  Cancelled,
  Error
}

export interface FormFields {
  size: string;
  cancellation: string;
  currency?: string;
  shipment?: string;
  payment?: string;
  other: string;
  startShipDate?: string;
  inWarehouseDate?: string;
  price?: string;
  color?: string;
  qty?: string;
  style?: string;
}

export interface FormData {
  listName: string;
  list: FormFields;
}

interface Props {
  poNumber: string;
  onCloseReturnPanel: () => void;
  poData: PO | undefined;
  disableEditPurchaseOrder: boolean;
}

export const PurchaseOrderReturnPanel: React.FC<Props> = ({
  onCloseReturnPanel,
  poNumber,
  poData,
  disableEditPurchaseOrder
}) => {
  const [mode, setMode] = useState(Mode.Default);
  const [heading, setHeading] = useState('');
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [enableCancel, setEnableCancel] = useState(false);
  const cleanItem = {
    poId: poData?.id ?? 0,
    request: null,
    variantId: null,
    changeType: null,
    skuCustomizationId: 0
  };
  const [item, setItem] = useState<Item>(cleanItem);
  const [requestChangeError, setRequestChangeError] = useState('');

  const formObject: PORequest = { purchaseOrderChangeRequests: [], items: [] };
  const [poChangeObject] = useState<PORequest>(formObject);
  let [poDetailsChange] = useState<PurchaseOrderChangeRequest[]>([]);
  let [poItems] = useState<Item[]>([]);

  const handlePOInformationChange = (name?: string, value = '') => {
    if (!name) return;
    if (value == '') {
      poChangeObject.purchaseOrderChangeRequests.splice(
        poChangeObject.purchaseOrderChangeRequests.findIndex(
          request => request.changeType === name
        ),
        1
      );
      if (poChangeObject.purchaseOrderChangeRequests.length == 0) {
        setEnableSubmit(false);
        setEnableCancel(false);
      }
      return;
    }
    const checkIfExists =
      poChangeObject?.purchaseOrderChangeRequests?.filter(req => req['changeType'] === name)
        .length !== 0;
    if (checkIfExists) {
      const previousValue = poChangeObject?.purchaseOrderChangeRequests?.find(
        req => req?.changeType === name
      );
      previousValue && previousValue.request ? (previousValue.request = value) : null;
    } else {
      const newPurchaseOrderChangeRequestsArray = {
        request: value,
        poId: item.poId,
        changeType: name
      };
      poChangeObject.purchaseOrderChangeRequests.push(newPurchaseOrderChangeRequestsArray);
    }
    setEnableSubmit(true);
    setEnableCancel(true);
  };

  const handleIndividualStylesChange = (name: string, value: string) => {
    if (value == '') {
      poChangeObject.items.splice(
        poChangeObject.items.findIndex(item => item.changeType === name),
        1
      );
      if (poChangeObject.items.length == 0) {
        setEnableSubmit(false);
        setEnableCancel(false);
      }
      return;
    }
    setItem({
      request: value,
      poId: item.poId,
      changeType: name,
      skuCustomizationId: 0,
      variantId: item.variantId
    });
    const checkIfExists =
      poChangeObject?.items?.filter(
        req => req.changeType === name && req.variantId === item.variantId
      ).length !== 0;
    if (checkIfExists) {
      const previousValue = poChangeObject.items.find(
        req => req?.changeType === name && req?.variantId === item?.variantId
      );
      previousValue && previousValue.request ? (previousValue.request = value) : null;
    } else {
      const newItemsArray = {
        request: value,
        poId: item.poId,
        changeType: name,
        skuCustomizationId: 0,
        variantId: item.variantId
      };
      poChangeObject.items.push(newItemsArray);
    }
    setEnableSubmit(true);
    setEnableCancel(true);
  };

  const handleDateSelect = (date: Date | null, label?: string) => {
    const dateString = date?.toLocaleDateString('en-CA');
    handlePOInformationChange(label, dateString);
  };

  const onCloseForm = useCallback(() => {
    setMode(Mode.Default);
    setHeading('');
  }, [setMode]);

  const onLabelClick = useCallback(
    (poId: number, variantId: number, style: string) => {
      setMode(Mode.EditPoStyle);
      setHeading(`for ${style}`);
      setItem({
        poId,
        request: '',
        variantId,
        changeType: '',
        skuCustomizationId: 0
      });
    },
    [setMode, setHeading]
  );

  const handleCancel = () => {
    setMode(Mode.Cancelled);
  };
  const [authToken, setAuthToken] = useState<string>();
  const { getAccessTokenSilently, isLoading } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then(Auth => setAuthToken(Auth));
  }, [getAccessTokenSilently, setAuthToken]);

  const trackRequestChangesSubmitted = useCallback(() => {
    tc.track('Request Changes Submitted', {
      addToScopeOnce: {
        order: poData
      }
    });
  }, [poData]);

  const handleSubmit = async () => {
    if (!authToken) return;
    const response = await fetch(`${PURCHASE_ORDERS}/${poNumber}/request-changes`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'no-referrer',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`
      },
      body: JSON.stringify(poChangeObject)
    });
    if (!response.ok) {
      const text = await response.text();
      setRequestChangeError(text);
      setMode(Mode.Error);
      return;
    }
    const data = await response.json();
    if (data) {
      setMode(Mode.Saved);
      trackRequestChangesSubmitted();
    }
  };

  const renderPOStyles = (item: { style: string; variantId: number }) => {
    return (
      <>
        <Text color="cement" className="PurchaseOrderReturnPanel__individual-style" treatment="h5">
          {item.style}
        </Text>
        <div>
          {poChangeObject.items.map(changeItem =>
            changeItem.variantId === item.variantId ? (
              <div key={changeItem.variantId}>
                <div>
                  <div>{changeItem['changeType']}</div>
                  <div>{changeItem['request']}</div>
                </div>
              </div>
            ) : null
          )}
        </div>
      </>
    );
  };

  const renderPOInformation = () => {
    return (
      <>
        <Text color="cement" className="PurchaseOrderReturnPanel__po-detail" treatment="h5">
          PO Details{' '}
        </Text>
        <Text color="cement" treatment="h6" className="PurchaseOrderReturnPanel__changed-content">
          cancel or change shipment, payment, etc.
        </Text>
        {poChangeObject.purchaseOrderChangeRequests.map(poChange => (
          <div key={poChange.poId} className="PurchaseOrderReturnPanel__change-list">
            <div className="PurchaseOrderReturnPanel__change">
              <div>{poChange.changeType}</div>
              <div>{poChange.request}</div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const renderRequestPanelData = () => {
    return (
      <>
        {disableEditPurchaseOrder ? (
          <div>{renderPOInformation()}</div>
        ) : (
          <Clickable
            className="PurchaseOrderReturnPanel__link"
            onClick={() => setMode(Mode.EditPo)}
          >
            {renderPOInformation()}
          </Clickable>
        )}
        <Text
          color="cornflower-blue"
          treatment="eyebrow"
          className="PurchaseOrderReturnPanel__heading"
        >
          INDIVIDUAL STYLES
        </Text>
        <Stack space={1} className="PurchaseOrderReturnPanel__content">
          {poData?.items?.map((item, index: number) => (
            <div key={index}>
              {disableEditPurchaseOrder ? (
                <div>{renderPOStyles(item)}</div>
              ) : (
                <Clickable
                  key={index}
                  name={item.style}
                  className="PurchaseOrderReturnPanel__link"
                  onClick={() => onLabelClick(item.poId, item.variantId, item.style)}
                >
                  {renderPOStyles(item)}
                </Clickable>
              )}
            </div>
          ))}
        </Stack>
      </>
    );
  };

  if (!authToken) return null;
  if (isLoading) return <Loading />;

  if (disableEditPurchaseOrder) {
    if (poData) {
      poDetailsChange = poData?.purchaseOrderChangeRequests.map(details => ({
        changeType: details.changeType,
        request: details.request,
        poId: poData.id
      }));
      poItems = poData?.items.map(item => ({
        poId: item.poId,
        request: item.changeRequests[0]?.request,
        variantId: item.variantId,
        changeType: item.changeRequests[0]?.changeType,
        skuCustomizationId: item.skuCustomizationId
      }));
    } else {
      poDetailsChange = [];
      poItems = [];
    }
    poChangeObject.purchaseOrderChangeRequests = poDetailsChange;
    poChangeObject.items = poItems;
  }

  return (
    <Stack space={0} className="PurchaseOrderReturnPanel">
      {mode === Mode.Default && (
        <>
          <Text className="PurchaseOrderReturnPanel__title" treatment="h5">
            {disableEditPurchaseOrder ? 'Changes Requested' : 'Request Changes for PO'}
          </Text>
          <Divider />
          <div className="PurchaseOrderReturnPanel__scrollable">
            <div className="PurchaseOrderReturnPanel__main-content">
              <Text
                color="cornflower-blue"
                treatment="eyebrow"
                className="PurchaseOrderReturnPanel__heading"
              >
                PO INFORMATION
              </Text>
              {renderRequestPanelData()}
            </div>
          </div>
          {!disableEditPurchaseOrder ? (
            <div className="PurchaseOrderReturnPanel__submit-section">
              <Divider />
              <Stack space={4} className="PurchaseOrderReturnPanel__submit">
                <Button
                  secondary
                  className="PurchaseOrderReturnPanel__submit-button"
                  disabled={!enableSubmit}
                  onClick={() => handleSubmit()}
                >
                  SUBMIT
                </Button>
              </Stack>
              <Stack space={4} className="PurchaseOrderReturnPanel__cancel">
                <ControlLink
                  className="PurchaseOrderReturnPanel__cancel-link"
                  disabled={!enableCancel}
                  underlined={false}
                  onClick={() => handleCancel()}
                >
                  Cancel changes
                </ControlLink>
              </Stack>
            </div>
          ) : (
            <div />
          )}
        </>
      )}
      {mode === Mode.EditPo && (
        <PurchaseOrderReturnPanelPOInfoChange
          mode={mode}
          heading={heading}
          formData={poChangeObject.purchaseOrderChangeRequests}
          onCloseForm={onCloseForm}
          handleDateSelect={handleDateSelect}
          handlePOInformationChange={handlePOInformationChange}
        />
      )}
      {mode === Mode.EditPoStyle && (
        <PurchaseOrderReturnPanelPOIndividualChange
          mode={mode}
          heading={heading}
          formData={poChangeObject.items.filter(function (poStyle) {
            return poStyle.variantId == item.variantId;
          })}
          onCloseForm={onCloseForm}
          handleIndividualStylesChange={handleIndividualStylesChange}
        />
      )}
      {(mode === Mode.Saved || mode === Mode.Cancelled || mode === Mode.Error) && (
        <PurchaseOrderReturnPanelModal
          mode={mode}
          onClose={onCloseForm}
          error={requestChangeError}
          onCloseReturnPanel={onCloseReturnPanel}
        />
      )}
    </Stack>
  );
};
