import React from 'react';
import { Router } from './routers/';
import { ScrollToTop } from './components/ScrollToTop';
import './App.scss';

export const App = () => {
  return (
    <div className="App">
      <ScrollToTop />
      <Router />
    </div>
  );
};
