import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { Stack } from '@moda/om';
import { FileUploader } from '../FileUploader';
import { PackageDetails } from '../PackageDetails';
import { ShippingDateSelector } from '../ShippingDateSelector';
import { ShippingInformation } from '../ShippingInformation';
import { Cartons, SingleCarton, CartonGroup, ShippingInformationData } from '../../types/data';
import './ShipmentDetails.scss';

interface Props {
  cartons: Cartons[];
  shippingDate: string;
  setShipmentDate: Dispatch<SetStateAction<string>>;
  setPackagingList: Dispatch<SetStateAction<CartonGroup>>;
  setCartonList: Dispatch<SetStateAction<SingleCarton[]>>;
  cartonList: SingleCarton[];
  packagingList: CartonGroup;
  uploadData: File[];
  fileSizeMb: number;
  fileSizeError: string;
  setFileSizeMb: Dispatch<SetStateAction<number>>;
  setFileSizeError: Dispatch<SetStateAction<string>>;
  shippingInformation: ShippingInformationData | undefined;
  setShippingInformationData: Dispatch<SetStateAction<ShippingInformationData | undefined>>;
  setCartons: Dispatch<SetStateAction<Cartons[]>>;
  setUploadData: Dispatch<SetStateAction<File[]>>;
  isDomesticShipment: boolean;
  isLanded: boolean;
}

export const ShipmentDetails: React.FC<Props> = ({
  cartons,
  setCartons,
  setUploadData,
  setShipmentDate,
  cartonList,
  packagingList,
  uploadData,
  shippingDate,
  shippingInformation,
  fileSizeMb,
  fileSizeError,
  setPackagingList,
  setCartonList,
  setShippingInformationData,
  setFileSizeMb,
  setFileSizeError,
  isDomesticShipment,
  isLanded
}) => {
  const handleDateChange = useCallback(
    (date: Date | null) => {
      const dateString = date?.toLocaleDateString('en-US') || '';
      setShipmentDate(dateString);
    },
    [setShipmentDate]
  );

  return (
    <Stack space={6} className="ShipmentDetails">
      <ShippingDateSelector
        heading="Shipping Date"
        label="Choose a shipping date"
        defaultValue={shippingDate}
        handleDateSelect={handleDateChange}
      />
      <PackageDetails
        setCartons={setCartons}
        cartons={cartons}
        setCartonList={setCartonList}
        packagingList={packagingList}
        setPackagingList={setPackagingList}
        cartonList={cartonList}
      />
      <ShippingInformation
        isLanded={isLanded}
        shippingInformationData={shippingInformation}
        setShippingInformationData={setShippingInformationData}
      />
      <FileUploader
        isDomesticShipment={isDomesticShipment}
        setUploadData={setUploadData}
        uploadData={uploadData}
        fileSizeMb={fileSizeMb}
        fileSizeError={fileSizeError}
        setFileSizeMb={setFileSizeMb}
        setFileSizeError={setFileSizeError}
      />
    </Stack>
  );
};
