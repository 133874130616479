import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { usePrevious } from '@moda/portal-stanchions';
import { PURCHASE_ORDERS } from '../paths';
import { useGetAuthToken } from '../../hooks/useGetAuthToken';
import { POShipmentData } from '../../types/data';
import { PurchaseOrder } from '../../hooks/usePoList';
import { reportError } from '../../lib/reportError';

const SIZE = 25;

enum Mode {
  Confirmed,
  Unconfirmed
}
export const useFetchPurchaseOrders = ({
  page,
  search,
  state,
  mode,
  previousSearch,
  previousPage,
  setPurchaseOrders
}: {
  page: number;
  search: string;
  state: string;
  mode: Mode;
  previousSearch: string | undefined;
  previousPage: number | undefined;
  setPurchaseOrders: Dispatch<SetStateAction<PurchaseOrder[] | []>>;
}): { orders: POShipmentData | undefined; errors: Error[] | null; loading: boolean } => {
  const [orders, setOrders] = useState<POShipmentData>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Error[] | null>(null);
  const previousMode = usePrevious(mode);
  const getAuthToken = useGetAuthToken();

  useEffect(() => {
    setLoading(true);
    const fetchData = (Auth: string | Error | undefined) => {
      fetch(`${PURCHASE_ORDERS}?page=${page}&size=${SIZE}&searchQuery=${search}&state=${state}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrer: 'no-referrer',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Auth}`
        }
      })
        .then(response => response.json())
        .then(data => {
          if (data.errors && data.errors[0]) {
            const { errors } = data;
            setLoading(false);
            setErrors(errors);
          }
          setLoading(false);
          if (data.totalElements == 0 || previousMode != mode || data.totalPages == 1) {
            setOrders(data);
            setPurchaseOrders(data.content);
          } else if (!data.empty) {
            setOrders((prevState: POShipmentData | undefined) => {
              return { ...prevState, ...data };
            });
            setPurchaseOrders((prevOrders: PurchaseOrder[] | []) => {
              return [...prevOrders, ...data.content];
            });
          }
        })
        .catch(error => {
          reportError('FETCH_PURCHASE_ORDERS', error);
          setLoading(false);
          setErrors(error);
        })
        .finally(() => {
          if (orders?.numberOfElements != 0) setLoading(false);
        });
    };
    getAuthToken().then(Auth =>
      previousMode != mode || previousSearch != search || page != previousPage
        ? fetchData(Auth)
        : null
    );
  }, [
    getAuthToken,
    state,
    previousMode,
    page,
    search,
    setPurchaseOrders,
    orders?.numberOfElements,
    mode,
    previousSearch,
    previousPage
  ]);

  return { orders, errors, loading };
};
