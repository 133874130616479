import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Input, Select, SelectableOption } from '@moda/om';
import { FormErrors } from '@moda/portal-stanchions';
import { COUNTRIES } from '../../../../constants';
import { Address } from '../../../../types/address';

import './ShippingAddressFormFields.scss';

export const COUNTRY_OPTIONS: SelectableOption[] = COUNTRIES.map(country => ({
  label: country.countryName,
  value: country.countryShortCode
}));

const getStateOrRegionOptions = (
  selectedCountryOrRegion: string
): SelectableOption[] | undefined => {
  return selectedCountryOrRegion
    ? COUNTRIES.find(country => country.countryShortCode === selectedCountryOrRegion)?.regions.map(
        ({ name, shortCode }: { name: string; shortCode?: string }) => ({
          label: name,
          value: shortCode ?? name
        })
      )
    : undefined;
};

interface Props {
  validationErrors: FormErrors;
  poShipFrom: Address | undefined;
}

export const ShippingAddressFormFields: React.FC<Props> = ({ validationErrors, poShipFrom }) => {
  const countryShortCode = 'USA';

  const [addressAttributes, setAddressAttributes] = useState<Address>(
    poShipFrom || {
      countryShortCode,
      firstName: '',
      lastName: '',
      phone: '',
      state: '',
      company: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: ''
    }
  );

  const addressRef = useRef<HTMLInputElement>(null);

  const stateOrRegionOptions = useMemo(
    () => getStateOrRegionOptions(addressAttributes.countryShortCode),
    [addressAttributes.countryShortCode]
  );

  const handleChange = useCallback((field: keyof Address, value: string) => {
    setAddressAttributes(address => ({
      ...address,
      [field]: value
    }));
  }, []);

  const handleCountryChange = useCallback((value: string) => {
    setAddressAttributes(address => ({
      ...address,
      countryShortCode: value
    }));
  }, []);

  return (
    <div className="ShippingAddressFormFields__fields">
      <Input label="Country or Region" name="countryShortCode">
        <Select
          idRef="CountryOrRegionDropdown"
          label=""
          onChange={handleCountryChange}
          options={COUNTRY_OPTIONS}
          value={addressAttributes.countryShortCode}
        />
      </Input>
      <Input
        className="ShippingAddressFormFields__field"
        label="Company*"
        name="company"
        onChange={event => handleChange('company', event)}
        value={addressAttributes.company}
        maxLength={50}
        error={validationErrors.company?.message}
      />
      <div className="ShippingAddressFormFields__group">
        <Input
          className="ShippingAddressFormFields__field"
          error={validationErrors.firstName?.message}
          label="First Name*"
          name="firstName"
          onChange={event => handleChange('firstName', event)}
          value={addressAttributes.firstName}
          maxLength={50}
        />
        <Input
          className="ShippingAddressFormFields__field"
          error={validationErrors.lastName?.message}
          label="Last Name*"
          name="lastName"
          onChange={event => handleChange('lastName', event)}
          value={addressAttributes.lastName}
          maxLength={50}
        />
      </div>

      <Input
        error={validationErrors.addressLine1?.message}
        label="Address*"
        name="addressLine1"
        onChange={event => handleChange('addressLine1', event)}
        onKeyPress={event => event.key === 'Enter' && event.preventDefault()}
        ref={addressRef}
        value={addressAttributes.addressLine1}
      />

      <Input
        label="Address Line 2(Floor, Apt #)"
        name="addressLine2"
        onChange={event => handleChange('addressLine2', event)}
        value={addressAttributes.addressLine2}
      />

      <div className="ShippingAddressFormFields__group">
        <Input
          className="ShippingAddressFormFields__field"
          error={validationErrors.city?.message}
          label="City"
          name="city"
          onChange={event => handleChange('city', event)}
          value={addressAttributes.city}
        />

        <Input
          className="ShippingAddressFormFields__field"
          error={validationErrors.state?.message}
          label="State or Region"
          name="state"
          onChange={event => handleChange('state', event)}
          value={addressAttributes.state}
        >
          {stateOrRegionOptions && (
            <Select
              idRef="StateOrRegionDropdown"
              error={validationErrors.state?.message}
              label=""
              onChange={value => handleChange('state', value)}
              options={stateOrRegionOptions}
              value={
                stateOrRegionOptions.find(option => option.label === addressAttributes.state)
                  ?.value || addressAttributes.state
              }
            />
          )}
        </Input>

        <Input
          className="ShippingAddressFormFields__field"
          error={validationErrors.postalCode?.message}
          label="Zip/Postal"
          name="postalCode"
          onChange={event => handleChange('postalCode', event)}
          value={addressAttributes.postalCode}
        />
      </div>

      <Input
        error={validationErrors['phone']?.message}
        label="Phone Number"
        name="phone"
        onChange={event => handleChange('phone', event)}
        value={addressAttributes.phone}
      />
    </div>
  );
};
