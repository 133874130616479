import React from 'react';
import { Stack } from '@moda/om';
import { Step } from './Step';

import './ProgressBar.scss';

interface Props {
  className?: string;
  mode: number;
}

enum Mode {
  AddItem,
  AddAddress,
  ShippingDetails,
  Review
}

export const ProgressBar: React.FC<Props> = ({ mode }) => (
  <Stack
    className="ProgressBar__steps"
    direction="horizontal"
    space={0}
    justifyContent="space-between"
  >
    <Step
      title="Add Item"
      checked={
        mode === Mode.AddItem ||
        mode === Mode.AddAddress ||
        mode === Mode.ShippingDetails ||
        mode === Mode.Review
      }
    />
    <Step
      title="Add Address"
      checked={mode === Mode.AddAddress || mode === Mode.ShippingDetails || mode === Mode.Review}
    />
    <Step
      title="Shipping Details"
      checked={mode === Mode.ShippingDetails || mode === Mode.Review}
    />
    <Step title="Review" checked={mode === Mode.Review} />
  </Stack>
);
