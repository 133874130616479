import React, { useEffect, useCallback, useState } from 'react';
import DownloadIcon from '@moda/icons/download-24';
import { Text, Stack, Clickable, Loading } from '@moda/om';
import FileSaver from 'file-saver';
import { Document, Page, pdfjs } from 'react-pdf';
import { useAuth0 } from '@auth0/auth0-react';
import { usePurchaseOrderPDF } from '../../../api/purchaseOrders/usePurchaseOrderPDF';
import { ErrorPage } from '../../../pages/ErrorPage/ErrorPage';
import { PURCHASE_ORDERS } from '../../../api/paths';

import './PurchaseOrderDetailPanel.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  withCredentials: true
};

interface Props {
  poNumber: string;
}

export const PurchaseOrderDetailPanel: React.FC<Props> = ({ poNumber }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [authToken, setAuthToken] = useState<string>();
  const { getAccessTokenSilently, isLoading, error } = useAuth0();
  const { data: pdf, errors } = usePurchaseOrderPDF({ poNumber });

  useEffect(() => {
    getAccessTokenSilently().then(Auth => setAuthToken(Auth));
  }, [getAccessTokenSilently, setAuthToken]);

  const handleDownloadPdf = useCallback(() => {
    if (!pdf) return;
    FileSaver.saveAs(pdf as Blob, `${poNumber}.pdf`);
  }, [pdf, poNumber]);

  if (!authToken) return null;
  if (isLoading) return <Loading />;
  if (error || errors) return <ErrorPage />;

  return (
    <div className="PurchaseOrderDetailPanel">
      <Stack
        space={1}
        direction="horizontal"
        justifyContent="space-between"
        className="PurchaseOrderDetailPanel__header"
      >
        <div className="PurchaseOrderDetailPanel__po-number">
          <Text treatment="h3" color="fog">
            PO #{poNumber}
          </Text>
        </div>

        <Clickable className="PurchaseOrderDetailPanel__download-text" onClick={handleDownloadPdf}>
          <Stack
            space={1}
            direction="horizontal"
            alignItems="center"
            className="PurchaseOrderDetailPanel__download-link"
          >
            <DownloadIcon /> Download
          </Stack>
        </Clickable>
      </Stack>
      <Document
        file={`${PURCHASE_ORDERS}/${poNumber}.pdf`}
        loading={<Loading />}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        options={{
          ...options,
          httpHeaders: {
            'Content-Type': 'application/pdf',
            Authorization: `Bearer ${authToken}`
          }
        }}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className="PurchaseOrderDetailPanel__pdf-page"
          />
        ))}
      </Document>
    </div>
  );
};
